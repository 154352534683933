import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { addStudentToParent, getPerson, getPersonList, updatePerson } from 'src/api/person';

import Layout from 'src/components/Layout/Layout';
import Badge from 'src/components/Badge/Badge';
import UserInfo from '../../components/UserInfo/UserInfo';
import SubLinks from '../../components/SubLinks/SubLinks';

import TripleDotsImg from '../../images/triple-dots.svg';
import FoldableTableRow from '../Teacher/FoldableTableRow';
import ProfileData from 'src/components/ProfileData/ProfileData';
import { PersonInterface, QueryInterface, UserType } from 'src/config/interfaces';
import { getFamiliesById } from 'src/api/family';
import { useTranslation } from 'react-i18next';
import useDisclosure from 'src/utils/useDisclosure';
import UserForm from 'src/components/UserForm/UserForm';
import SelectProfile from 'src/components/SelectProfile/SelectProfile';
import LoadingLayout from 'src/components/Layout/Loading';


function Student() {
  const params = useParams();
  const { t } = useTranslation();
  const { data, isLoading }: QueryInterface = useQuery(['student', params.studentId], () => getPerson(params.studentId || '', 'student'));
  const { data: familyData } = useQuery(['family', data?.person.familyId], () => getFamiliesById(data?.person.familyId || ''), {
    enabled: !!data?.person.familyId
  });
  console.log(familyData)
  const queryClient = useQueryClient();
  const doUpdateFamily = useMutation(
    ({ parentId, studentId }: any) => addStudentToParent(parentId, studentId),
    {
    onSuccess: () => {
      queryClient.invalidateQueries(['family', data?.person.familyId]);
    }
  });
  const doUpdateStudent = useMutation(
    ({ studentData }: any) => updatePerson(studentData, 'student'),
    {
    onSuccess: () => {
      queryClient.invalidateQueries(['student', params.studentId]);
    }
  });
  const selectParentModal = useDisclosure();
  const newParentModal = useDisclosure();
  const { data: parentsData } = useQuery('parentIds', () => getPersonList('parent'));
  const parentIds = parentsData ? parentsData.values : [];

  const studentSubLinks = [
    {
      title: 'Overview',
      id: 'overview',
      url: `/management/students/student/${params.studentId}`,
    },
    {
      title: 'Schedule',
      id: 'schedule',
      url: `/management/students/student/${params.studentId}/schedule`,
    },
    {
      title: 'Logs',
      id: 'logs',
      url: `/management/students/student/${params.studentId}/logs`,
    },
  ];

  const addToFamily = (type: UserType, userData: PersonInterface | undefined) => async (data: any, parentId: string) => {
    doUpdateFamily.mutate(
      { parentId, studentId: params.studentId }
    )
  };

  const setMainParent = (id: string) => {
    doUpdateStudent.mutate(
      { studentData: {
        ...data?.person,
        primaryParentId: id,
      } }
    )
  }

  return (
    <Layout>
      {newParentModal.isOpen && (
        <UserForm
          onHide={newParentModal.onClose}
          type='parent'
          title="Add new parent"
          callBack={addToFamily('parent', data?.person)}
          selectDependant='student'
          selectedDependantId={params.studentId}
          familyId={familyData.value.id}
        />
      )}
      {selectParentModal.isOpen && (
        <SelectProfile
          onHide={selectParentModal.onClose}
          type='parent'
          title="Select parent"
          ids={parentIds}
          callBack={(profileId: string) => addToFamily('parent', data?.person)({}, profileId)}
        />
      )}
      <Container>
        <LoadingLayout isLoading={isLoading}>
          <Row>
            <Col xl={3}>
              <UserInfo userData={data} />
            </Col>
            <Col xl={9}>
              <SubLinks sublinks={studentSubLinks} />

              <div className='mt-5'>
                <div className='Teacher-detailsblock'>
                  <Row>
                    <Col className='Teacher-detailsblock-title'>
                      {t('parents')}
                    </Col>
                    <Col className='text-end'>
                      <Button className='td-none' variant="link" onClick={() => selectParentModal.onOpen()}>Add</Button>
                      <Button className='td-none' variant="link" onClick={() => newParentModal.onOpen()}>Create</Button>
                    </Col>
                  </Row>
                </div>
                <Table borderless className='Teachers-table Teachers-table-nofirstpadding'>
                  <thead>
                    <tr>
                      <th style={{ width: '70%' }}>Name</th>
                      <th />
                      <th className="text-center">{t('mainParent')}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {(familyData?.value.parentIds || [])
                    .map((id: string) => (
                      <tr key={id} style={{ verticalAlign: 'middle' }}>
                        <td>
                          <ProfileData
                            type="parent"
                            id={id}
                            variant="short-profile"
                          />
                        </td>
                        <td>
                          <Link to={`/management/parents/parent/${id}`}>Details</Link>
                        </td>
                        <td className="text-center">
                          {data?.person?.primaryParentId === id ? (
                            <>{t('isMainParent')}</>
                          ) : (
                            <Button variant='link' size="sm" onClick={() => setMainParent(id)}>
                              {t('setMainparent')}
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className='mt-5'>
                <div className='Teacher-detailsblock'>
                  <Row>
                    <Col className='Teacher-detailsblock-title'>
                      {t('siblings')}
                    </Col>
                  </Row>
                </div>
                <Table borderless className='Teachers-table Teachers-table-nofirstpadding'>
                  <thead>
                    <tr>
                      <th style={{ width: '70%' }}>Name</th>
                      <th />
                      <th>Status</th>
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    {(familyData?.value.childIds || [])
                    .filter((id: string) => id !== params.studentId)
                    .map((id: string) => (
                      <tr key={id}>
                        <td>
                          <ProfileData
                            type="student"
                            id={id}
                            variant="short-profile"
                          />
                        </td>
                        <td>
                          <Link to={`/management/students/student/${id}`}>Details</Link>
                        </td>
                        <td>
                          <Badge type='success'>
                            Active
                          </Badge>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className='mt-5'>
                <div className='Teacher-detailsblock'>
                  <Row>
                    <Col className='Teacher-detailsblock-title'>
                      Teachers
                    </Col>
                    <Col className='text-end'>
                      <a href="###">Create</a>
                    </Col>
                  </Row>
                </div>
                <Table borderless className='Teachers-table Teachers-table-nofirstpadding'>
                  <thead>
                    <tr>
                      <th />
                      <th style={{ width: '30%' }}>Title</th>
                      <th>Status</th>
                      <th style={{ width: '150px' }}>Activities</th>
                      <th style={{ width: '150px' }}>Manager</th>
                      <th style={{ width: '150px' }}>Created</th>
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    <FoldableTableRow
                      totalRows={6}
                      cells={(
                        <>
                          <td>
                            <Row className='valign-middle'>
                              <Col xs={2}>
                                <Image
                                  src="/tmp/user.png"
                                  roundedCircle
                                  className='Table-user-image'
                                />
                              </Col>
                              <Col>
                                <div>
                                  School #232323
                                </div>
                                <div className='gray text-uppercase'>Photo SCHOOL</div>
                              </Col>
                            </Row>
                          </td>
                          <td>
                            <Badge type='success'>
                              Active
                            </Badge>
                          </td>
                          <td>
                            <a href="###">123</a>
                          </td>
                          <td>
                            <Link to="/management/teachers/teacher/1">Santiago Marín Álvarez</Link>
                          </td>
                          <td>
                            March 21, 18:00
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle className='Action-Menu-btn' id="dropdown-basic">
                                <Image src={TripleDotsImg} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </>
                      )}
                      data={(
                        <>
                          <Row className='mt-3'>
                            <Col xl={3} className='gray'>
                              Name of activity
                            </Col>
                            <Col xl={9}>
                              Capoera
                            </Col>
                          </Row>
                          <Row className='mt-3'>
                            <Col xl={3} className='gray'>
                              Price
                            </Col>
                            <Col xl={9}>
                              $25.00 per month
                            </Col>
                          </Row>
                          <Row className='mt-3'>
                            <Col xl={3} className='gray'>
                              Category
                            </Col>
                            <Col xl={9}>
                              None
                            </Col>
                          </Row>
                          <Row className='mt-3'>
                            <Col xl={3} className='gray'>
                              Full description
                            </Col>
                            <Col xl={9}>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                            </Col>
                          </Row>
                          <Row className='mt-3'>
                            <Col xl={3} className='gray'>
                              Teacher
                            </Col>
                            <Col xl={9}>
                              <Link to='/management/teachers'>Aleksandr Ivanov</Link>
                            </Col>
                          </Row>
                        </>
                      )}
                    />
                  </tbody>
                </Table>
              </div>

            </Col>
          </Row>
        </LoadingLayout>
      </Container>
    </Layout>
  );
}

export default Student;
