import classNames from 'classnames';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import { useTranslation } from "react-i18next";
import useDisclosure from 'src/utils/useDisclosure';

import './foldable.scss';

interface FoldableProps {
  children: Array<any> | string | object | number | boolean,
  legend: any,
  actions?: Array<any> | string | object | number | boolean,
  isOpen?: boolean
}

const Foldable = ({ children, legend, actions, isOpen }: FoldableProps) => {
  const detailsData = useDisclosure(isOpen);
  const { t } = useTranslation();

  return (
    <div className={classNames('Foldable', {
      'Foldable-open': detailsData.isOpen,
    })}>
      <div className='Foldable-legend mb-3'>
        <Row>
          <Col
            role="button"
            tabIndex={0}
            className='Foldable-clickable bold'
            onClick={() => {
              if (detailsData.isOpen)
                return detailsData.onClose();
                
              return detailsData.onOpen();
            }}
          >
            {legend || t('details')}
          </Col>
          {!!actions && (
            <Col className='text-end'>
              {actions}
            </Col>
          )}
        </Row>
      </div>
      <Collapse unmountOnExit timeout={100} in={detailsData.isOpen}>
        <div className='Foldable-data'>
          {children}
        </div>
      </Collapse>
    </div>
  )
};

export default Foldable;
