import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProfileData from '../ProfileData/ProfileData';
import { UserType } from 'src/config/interfaces';
import { ReactNode } from 'react';

import TripleDotsImg from '../../images/triple-dots.svg';

interface PrfoliesListInterface {
  title: string | ReactNode,
  addPerson?: Function,
  deletePerson?: Function,
  profileIds: string[],
  type: UserType,
}

const ProfilesList = ({
  title,
  addPerson,
  deletePerson,
  profileIds,
  type,
}: PrfoliesListInterface) => {
  const { t } = useTranslation();
  return (
    <div className='mt-5'>
      <div className='Teacher-detailsblock'>
        <Row>
          <Col className='Teacher-detailsblock-title'>
            {title}
          </Col>
          <Col className='text-end'>
            {!!addPerson && (<Button className='td-none' variant="link" onClick={() => addPerson()}>{t('add')}</Button>)}
          </Col>
        </Row>
      </div>
      <Table borderless className='Teachers-table Teachers-table-nofirstpadding'>
        <thead>
          <tr>
            <th style={{ width: '70%' }}>Name</th>
            <th />
            <th className='text-end' />
          </tr>
        </thead>

        <tbody>
          {(profileIds || [])
            .map((profileId: string) => (
            <tr key={profileId}>
              <td>
                <ProfileData
                  type={type}
                  id={profileId}
                  variant="short-profile"
                />
              </td>
              <td>
                <Link to={`/management/${type}s/${type}/${profileId}`}>
                  {t('details')}
                </Link>
              </td>
              <td className='text-end'>
                <Dropdown>
                  <Dropdown.Toggle className='Action-Menu-btn' id="dropdown-basic">
                    <Image src={TripleDotsImg} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {!!deletePerson && (
                      <Dropdown.Item onClick={() => deletePerson(type, profileId)}>{t('delete')}</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table> 
    </div>
  );
}

export default ProfilesList;
