import * as React from 'react';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import './navigation.scss';

function Navigation() {
  const { t } = useTranslation();

  const navigationItems = [
    {
      name: t('home'),
      link: '/management/home',
    },
    {
      name: t('students'),
      link: '/management/students',
    },
    {
      name: t('teachingTeam'),
      link: '/management/teachers',
    },
    {
      name: t('activities'),
      link: '/management/activities',
    },
    // {
    //   name: t('documents'),
    //   link: '/management/documents/all/1',
    // },
    {
      name: t('users'),
      link: '/management/users/all/1',
    },
    {
      name: t('reports'),
      link: '/management/reports',
    }
    // {
    //   name: t('payments'),
    //   link: '/management/payments/all/1',
    // },
    // {
    //   name: t('notifications'),
    //   link: '/management/notifications',
    // },
  ];

  return (
    <div className='Navigation'>
      <Container>
        <nav>
          <ul>
            {
              navigationItems.map((navItem) => (
                <li key={navItem.link}>
                  <NavLink
                    to={navItem.link}
                  >
                    {navItem.name}
                  </NavLink>
                </li>
              ))
            }
          </ul>
        </nav>
      </Container>
    </div>
  );
}

export default Navigation;
