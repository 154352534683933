import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';

import './teacher.scss';
import Layout from 'src/components/Layout/Layout';
import UserInfo from '../../components/UserInfo/UserInfo';
import SubLinks from '../../components/SubLinks/SubLinks';

import TripleDotsImg from '../../images/triple-dots.svg';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ActivityQueryInterface, QueryInterface } from 'src/config/interfaces';
import { getPerson } from 'src/api/person';
import { getTeacherActivitiesToday } from 'src/api/activities';
import LoadingLayout from 'src/components/Layout/Loading';

function Teacher() {
  const params = useParams();
  const { t } = useTranslation();
  const { data, isLoading }: QueryInterface = useQuery(['teacher', params.teacherId], () => getPerson(params.teacherId || '', 'teacher'));
  const { data: teacherActivitiesToday }: ActivityQueryInterface = useQuery(['teacherActivities', params.teacherId], () => getTeacherActivitiesToday());
  // const queryClient = useQueryClient();

  const teacherSubLinks = [
    {
      title: 'Overview',
      id: 'overview',
      url: `/management/teachers/teacher/${params.teacherId}`,
    },
    {
      title: 'Schedule',
      id: 'schedule',
      url: `/management/teachers/teacher/${params.teacherId}/schedule`,
    },
    {
      title: 'Logs',
      id: 'logs',
      url: `/management/teachers/teacher/${params.teacherId}/logs`,
    },
  ];

  return (
    <Layout>
      <Container>
        <LoadingLayout isLoading={isLoading}>
          <Row>
            <Col xl={3}>
            <UserInfo userData={data} />
            </Col>
            <Col xl={9}>
              <SubLinks sublinks={teacherSubLinks} />
              <div className='mt-5'>
                <div className='Teacher-detailsblock'>
                  <Row>
                    <Col className='Teacher-detailsblock-title'>
                      {t('activities')}
                    </Col>
                  </Row>
                </div>
                <Table borderless className='Teachers-table Teachers-table-nofirstpadding'>
                  <thead>
                    <tr>
                      <th style={{ width: '70%' }}>Name</th>
                      <th />
                      <th>{t('date')}</th>
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    {(teacherActivitiesToday?.values || [])
                      .map((activity) => (
                        <tr>
                          <td>
                            <Row className='valign-middle'>
                              <Col style={{ width: '3rem', flex: 0 }}>
                                <Image
                                  src={activity.image}
                                  roundedCircle
                                  className='Table-user-image'
                                />
                              </Col>
                              <Col>
                                <div>
                                  {activity.name}
                                </div>
                              </Col>
                            </Row>
                          </td>
                          <td>
                            <Link to={`/management/activities/activity/${activity.id}`}>Details</Link>
                          </td>
                          <td>
                            {new Date(activity.date).toLocaleString()}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>

              <div className='mt-5'>
                <div className='Teacher-detailsblock'>
                  <Row>
                    <Col className='Teacher-detailsblock-title'>
                      Events
                    </Col>
                    <Col className='text-end'>
                      <a href="###">Create</a>
                    </Col>
                  </Row>
                </div>
                <Table borderless className='Teachers-table Teachers-table-nofirstpadding'>
                  <thead>
                    <tr>
                      <th>
                        Date
                      </th>
                      <th>Title</th>
                      <th>Activity</th>
                      <th>Teacher</th>
                      <th>Venue</th>
                      <th>Students</th>
                      <th />
                    </tr>
                    <tr className="Teachers-table-colored-links valign-middle">
                      <td>
                        March 24, 17:30
                      </td>
                      <td className='gray'>
                        Piano lessons with Adriano
                      </td>
                      <td>
                        <a href="###">Piano lesson</a>
                      </td>
                      <td>
                        <Link to="/management/teachers/teacher/1">María Ramos Bonito</Link>
                      </td>
                      <td>
                        <Link to="/management/venues/venue/1">Piano School</Link>
                      </td>
                      <td className='gray'>
                        11/24
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle className='Action-Menu-btn' id="dropdown-basic">
                            <Image src={TripleDotsImg} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  </thead>
                </Table>
              </div>
            </Col>
          </Row>
        </LoadingLayout>
      </Container>
    </Layout>
  );
}

export default Teacher;
