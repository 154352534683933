import * as React from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import Layout from 'src/components/Layout/Layout';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import { useTranslation, Trans } from "react-i18next";

import CalendarImg from '../../images/calendar.svg';
import useDisclosure from 'src/utils/useDisclosure';
import NoResults from 'src/components/NoResults/NoResults';
import ActivityForm from 'src/components/ActivityForm/ActivityForm';
import EventForm from 'src/components/EventForm/EventForm';
import { useQueries, useQuery, useQueryClient } from 'react-query';
import { getActivity, getActivityList } from 'src/api/activities';
import { ActivityInterface, ActivityType } from 'src/config/interfaces';

import ActivityDefaultImg from '../../images/event-default.svg';
import LoadingLayout from 'src/components/Layout/Loading';
import formatDate from 'src/utils/formatDate';


function Activities() {
  const { type } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkedItems, setCheckedItems] = React.useState({});
  const newActivityModal = useDisclosure();
  const newEventModal = useDisclosure();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery('activityIds', () => getActivityList());

  const activityIds = data && data.values ? data.values : [];

  useQueries(
    activityIds.map((id: string) => ({
      queryKey: ['activity', id],
      queryFn: () => getActivity(id),
    })),
  );

  const activities: Array<ActivityInterface> = activityIds.map((id: string) => queryClient.getQueryData(['activity', id]));

  var sortedArray: { value?: ActivityType; }[] = activities.sort((n1,n2) => {

    if (n1.value && n2.value){
      if (n1.value.date > n2.value.date) {
          return 1;
      }

      if (n1.value.date < n2.value.date) {
          return -1;
      }
    }
    return 0;
  });

  const activitiesMenu = [
    {
      type: 'link',
      name: t('allActivities'),
      link: '/management/activities/all',
    },
  ];

  React.useEffect(() => {
    if (!type) {
      navigate('/management/activities/all')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const checkIfAllChecked = (): boolean => {
    const checkedLength = Object.entries(checkedItems).filter(item => item[1] === true).length;
    const studentLength = activities.length;
    return checkedLength === studentLength;
  }

  const getChecked = (): number => {
    return Object.entries(checkedItems).filter(item => item[1] === true).length;
  }

  const checkIfChecked = (): boolean => {
    const checkedLength = Object.entries(checkedItems).filter(item => item[1] === true).length;
    return checkedLength > 0;
  }

  const handleCheckAll = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (!target.checked) {
      setCheckedItems({});
    } else {
      const newCheckedItems = {};

      Object.entries(activities).forEach((el) => {
        if (el[1].value?.id) {
          newCheckedItems[el[1].value?.id] = true;
        }
      });

      setCheckedItems(newCheckedItems);
    }
  }

  const uncheckAll = () => {
    setCheckedItems({});
  }

  const handleCheckItem = (id?: string) => {
    if (!id) {
      return ;
    }
    const newCheckedItems = {...checkedItems};

    if (newCheckedItems[id]) {
      delete newCheckedItems[id];
    } else {
      newCheckedItems[id] = true;
    }
    setCheckedItems(newCheckedItems);
  };

  return (
    <>
      {newActivityModal.isOpen && (
        <ActivityForm
          onHide={newActivityModal.onClose}
        />
      )}

      {newEventModal.isOpen && (
        <EventForm
          onHide={newEventModal.onClose}
        />
      )}
      <Layout>
        <Container>
          <Row>
            <Col xl={2}>
              <menu className='SubNavigation'>
                <h5>{t('activities')}</h5>
                <ul className='mt-4'>
                  {
                    activitiesMenu.map((item) => (
                      <li key={item.link}>
                        <NavLink
                          to={item.link}
                          className={`SubNavigation-${item.type}`}
                        >
                          {item.name}
                        </NavLink>
                      </li>
                    ))
                  }
                </ul>
              </menu>
            </Col>
            <Col xl={10}>
              <LoadingLayout isLoading={isLoading}>
                <Row>
                  <Col>
                    <h3 className='bold'>
                      {t('allActivities')}
                      
                      {activities.length > 0 && (
                        <span className='Teachers-total'>{activities.length}</span>
                      )}
                    </h3>
                  </Col>
                  <Col className='Table-actions'>
                    {checkIfChecked() ? (
                      <div>

                        <div className='Teachers-checkedInfo'>
                          <span>
                            {getChecked()} {t('selected')}
                          </span>
                          <button
                            className='Button Button-link'
                            onClick={uncheckAll}
                          >
                            {t('deselect')}
                          </button>
                        </div>

                        <button
                          className="Button"
                        >
                          ↗ {t('export')}
                        </button>

                        <button
                          className="Button Button-danger"
                        >
                          {t('delete')}
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="Button"
                        >
                          {t('import')}
                        </button>

                        <button
                          className="Button"
                        >
                          {t('filter')}
                        </button>

                        <button
                          className="Button Button-primary"
                          onClick={() => newActivityModal.onOpen()}
                        >
                          + {t('newActivity')}
                        </button>

                        <button
                          className="Button Button-primary"
                          onClick={() => newEventModal.onOpen()}
                        >
                          + {t('newEvent')}
                        </button>
                      </div>
                    )}
                  </Col>
                </Row>
                {sortedArray.length ? (
                  <>
                  <Table borderless className='Teachers-table mt-4'>
                    <thead>
                      <tr>
                        <th
                          className='Teachers-table-checkbox'
                        >
                          <label className='Checkbox'>
                            <input
                              type="checkbox"
                              checked={checkIfAllChecked()}
                              onChange={handleCheckAll}
                            />
                            <span />
                          </label>
                        </th>
                        <th>{t('name')}</th>
                        <th>{t('date')}</th>
                        <th className='Teachers-table-details' />
                        <th className='Teachers-table-calendar' />
                        <th>{t('students')}</th>
                        <th>{t('teachers')}</th>
                        <th>{t('venue')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        sortedArray?.map((act: ActivityInterface, idx: number) => {
                          const activity = act?.value;
                          if (!activity) return null;
                          return (
                            <tr key={activity?.id || idx}>
                              <td>
                                <label className='Checkbox'>
                                  <input
                                    type="checkbox"
                                    checked={checkedItems[activity?.id || ''] === true}
                                    onChange={() => handleCheckItem(activity?.id)}
                                  />
                                  <span />
                                </label>
                              </td>
                              <td>
                                <Row>
                                  <Col style={{ width: '3rem', flex: 0 }}>
                                    <Image
                                      src={activity?.image || ActivityDefaultImg}
                                      roundedCircle
                                      className='Table-user-image'
                                    />
                                  </Col>
                                  <Col>
                                    {activity?.name}
                                  </Col>
                                </Row>
                              </td>
                              <td className='gray'>
                                {formatDate(activity?.date).fullDate()}
                              </td>
                              <td>
                                <Link to={`/management/activities/activity/${activity?.id}`}>{t('details')}</Link>
                              </td>
                              <td>
                                <Image src={CalendarImg} />
                              </td>
                              <td className='no-wrap' style={{ maxWidth: '200px' }}>
                                {activity?.students?.length}
                              </td>
                              <td className='no-wrap' style={{ maxWidth: '200px' }}>
                                {activity?.teacherIds?.length}
                              </td>
                              <td className='gray'>
                                {activity?.venueId}
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                  <div className='Teachers-table-info mt-5'>
                    <Row>
                      <Col>
                        <Trans
                          i18nKey="resultsInfo" // optional -> fallbacks to defaults if not provided
                          defaults={t('resultsInfo')} // optional defaultValue
                          values={{ from: 1, to: activities.length, total: activities.length, }}
                          components={{ strong: <strong /> }}
                        />
                      </Col>
                      <Col className='Table-page-btns'>
                        <button
                          className='Table-page-btn'
                          disabled
                        >
                          ← {t('previous')}
                        </button>
                        <button
                          disabled
                          className='Table-page-btn'
                        >
                          {t('next')} →
                        </button>
                      </Col>
                    </Row>
                  </div>
                </>
                ) : (
                  <NoResults
                    title="Add first activity"
                    action={(
                      <button
                        className="Button Button-primary"
                        onClick={() => newActivityModal.onOpen()}
                      >
                        + {t('newActivity')}
                      </button>
                    )}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </NoResults>
                )}
              </LoadingLayout>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}

export default Activities;
