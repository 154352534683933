import * as React from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Layout from 'src/components/Layout/Layout';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";

import { useQuery } from 'react-query';
import { getReportList } from 'src/api/reports';
import { ReportsQueryInterface } from 'src/config/interfaces';

import LoadingLayout from 'src/components/Layout/Loading';
import formatDate from 'src/utils/formatDate';
import formatTime from 'src/utils/formatTime';


function Reports() {
    const { type } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data, isLoading }: ReportsQueryInterface = useQuery(['reports'], () => getReportList());
  
    const activitiesMenu = [
      {
        type: 'link',
        name: t('allReports'),
        link: '/management/reports',
      },
    ];
  
    React.useEffect(() => {
      if (!type) {
        navigate('/management/reports')
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])
  
    return (
      <>
        <Layout>
          <Container>
            <Row>
              <Col xl={2}>
                <menu className='SubNavigation'>
                  <h5>{t('reports')}</h5>
                  <ul className='mt-4'>
                    {
                      activitiesMenu.map((item) => (
                        <li key={item.link}>
                          <NavLink
                            to={item.link}
                            className={`SubNavigation-${item.type}`}
                          >
                            {item.name}
                          </NavLink>
                        </li>
                      ))
                    }
                  </ul>
                </menu>
              </Col>
              <Col xl={10}>
                <LoadingLayout isLoading={isLoading}>
                  <Row>
                    <Col>
                      <h3 className='bold'>
                        {t('allReports')}
                        {data && data.length > 0  && (
                          <span className='Teachers-total'>{data.length}</span>
                        )}
                      </h3>
                    </Col>
                  </Row>
                    <>
                    <Table borderless className='Teachers-table Teachers-table-nofirstpadding'>
                            <thead>
                                <tr>
                                <th></th>
                                <th>Nombre estudiante</th>
                                <th>Apellido estudiante</th>
                                <th>Nombre autorizado</th>
                                <th>Apellido autorizado</th>
                                <th>Rol</th>
                                <th>Fecha</th>
                                <th>Hora</th>
                                <th>Actividad/Evento</th>
                                <th>Nombre profesor</th>
                                <th>Apellido profesor</th>
                                </tr>
                            </thead>
                            {
                                Object.values(data?.values || []).map((value, index) => {
                                  console.log(value)
                                    return (
                                    <tr>
                                        <input type="checkbox" style={{marginTop:25}} />
                                        <td>{value["student"]["firstName"]}</td>
                                        <td>{value["student"]["lastName"]}</td>
                                        <td>{value["authorizedPerson"]["firstName"]}</td>
                                        <td>{value["authorizedPerson"]["lastName"]}</td>
                                        <td>{value["authorizedPersonProfile"]["type"]}</td>
                                        <td>{formatDate(value["date"]).fullDate()}</td>
                                        <td>{formatTime(value["date"]).fullTime()}</td>
                                        <td>{value["event"]["name"]}</td>
                                        <td>{value["teacher"]["firstName"]}</td>
                                        <td>{value["teacher"]["lastName"]}</td>
                                    </tr>
                                    )
                                })
                            }            
                    </Table>
                  </>
                </LoadingLayout>
              </Col>
            </Row>
          </Container>
        </Layout>
      </>
    );
  }

export default Reports;