import * as React from 'react';

interface InfoBlockInterface {
  label: any,
  children: Array<any> | string | object | number | boolean | any,
}

function InfoBlock({ label, children }: InfoBlockInterface) {

  return (
    <div className='mt-4'>
      <div className='gray'>
        {label}
      </div>
      {children}
    </div>
  );
}

export default InfoBlock;
