import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import LoadingLayout from 'src/components/Layout/Loading';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { createRecurrentActivity } from 'src/api/activities';
import { useTranslation } from "react-i18next";
import { getPerson, getPersonList } from 'src/api/person';
import { useQueries, useQuery } from 'react-query';
import { ActivityType, UserInterface } from 'src/config/interfaces';
import TextField from '@mui/material/TextField';

import './ActivityForm.scss';
interface ActivityProps {
  onHide: Function,
  title?: string,
}

function ActivityForm({
  onHide,
  title,
}: ActivityProps) {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      description: '',
      days: [],
      hours: [],
      repeated_weeks:0,
      teacherIds: [],
      students: [],
    },
  });

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { data: teacherData, isLoading: teacherLoading } = useQuery('teachersIds', () => getPersonList("teacher"));
  const teachersIds = teacherData && teacherData.values ? teacherData.values : [];
  useQueries(
    teachersIds.map((id: string) => ({
      queryKey: ['teacher', id],
      queryFn: () => getPerson(id,"teacher"),
    })),
  );
  const teachers: Array<UserInterface> = teachersIds.map((id: string) => queryClient.getQueryData(['teacher', id]));

  const days = ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"]

  const { data: studentsData, isLoading: studentsLoading } = useQuery('studentIds', () => getPersonList("student"));
  const studentsIds = studentsData && studentsData.values ? studentsData.values : [];

  useQueries(
    studentsIds.map((id: string) => ({
      queryKey: ['student', id],
      queryFn: () => getPerson(id,"student"),
    })),
  );

  const students: Array<UserInterface> = studentsIds.map((id: string) => queryClient.getQueryData(['student', id]));


  const activityQuery = useMutation(
    (data: ActivityType) => createRecurrentActivity(data),
    {
      onSuccess: (data) => {
        onHide();
        console.log(data)
        queryClient.invalidateQueries(`activityIds`);
      },
      onError: () => {
        window.alert('Error');
      }
    }
  );

  const onActivityCreate = (data: any) => {
    console.log(data)
    activityQuery.mutate(data);
  };
  

  return (
    <Modal show={true} onHide={() => onHide()}>
      <form onSubmit={handleSubmit(onActivityCreate)}>
        <Modal.Header className='bold'>
          {title || 'Add activity'}
        </Modal.Header>
        <Modal.Body>

          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Event name</Form.Label>
            <input
              className='form-control'
              type="name" 
              placeholder=""
              {...register('name', {
                required: true,
              })}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Short description</Form.Label>
            <textarea
              className='form-control' 
              placeholder=""
              {...register('description', {
                required: true,
              })}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="category">
            <Form.Label>Days</Form.Label>
            <div className="form-control" >
              {days?.map((day,i) => {
                return(
                  <>
                  <div style={{display:"flex",alignItems:'center'}}>
                  <input 
                  type="checkbox" 
                  id={day} 
                  value={day}
                  {...register('days', {
                    required: true,
                  })}
                  ></input>
                  <label className='days_label'>{t(day)}</label>
                  <TextField
                  id="time"
                  type="time"
                  size='small'
                  defaultValue="08:00"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 600,
                  }}
                  sx={{ width: 150 }}
                  style={{marginLeft:10}}
                  {...register('hours', {
                    required: false,
                  })}
                  />
                  </div>
                  <br></br>
                  </>
                )
              })}
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="repeated_weeks">
            <Form.Label>Numero de semanas que durara el evento</Form.Label>
            <input
              className='form-control'
              type="number" 
              placeholder=""
              {...register('repeated_weeks', {
                required: true,
              })}
            />
          </Form.Group>
          <Form.Label>Profesores</Form.Label>
          <LoadingLayout isLoading={teacherLoading}>
            <Form.Group className="mb-3" controlId="repeated_weeks">
              {teachers?.map((teach: UserInterface, idx: number) => {
                const teacher = teach;
                if (!teacher) return null;
                return(
                  <>
                  <div>
                    <input
                      type="checkbox" 
                      id={teacher["person"]["id"]}
                      value={teacher["person"]["id"]}
                      {...register('teacherIds', {
                        required: false,
                      })}
                    ></input>
                    <label className='days_label'>{teacher["user"]["firstName"]}</label>
                    <label className='days_label'>{teacher["user"]["lastName"]}</label>
                  </div>
                  </>
                )
              })}
            </Form.Group>
          </LoadingLayout>
          <Form.Label>Alumnos</Form.Label>
          <LoadingLayout isLoading={studentsLoading}>
            <Form.Group className="mb-3" controlId="repeated_weeks">
              {students?.map((stud: UserInterface, idx: number) => {
                const student = stud;
                if (!student) return null;
                return(
                  <>
                  <div>
                    <input
                      type="checkbox" 
                      id={student["person"]["id"]}
                      value={student["person"]["id"] +" "+ student["user"]["id"]}
                      {...register('students', {
                        required: false,
                      })}
                    ></input>
                    <label className='days_label'>{student["user"]["firstName"]}</label>
                    <label className='days_label'>{student["user"]["lastName"]}</label>
                  </div>
                  </>
                )
              })}
            </Form.Group>
          </LoadingLayout>
        </Modal.Body>
        <Modal.Footer className='text-end'>
          <button className='Button' type="button" onClick={() => onHide()}>
            Cancel
          </button>
          {' '}
          <button className='Button Button-primary' type="submit">
            Add activity
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default ActivityForm;
