import * as React from 'react';
import Loader from '../Loader/Loader';

interface LayoutLoadingInterface {
  children: Array<any> | string | object | number | boolean,
  isLoading: boolean,
}

function LoadingLayout({ children, isLoading }: LayoutLoadingInterface) {
  if (isLoading) return <Loader />;
  
  return (
    <>
      {children}
    </>
  );
};

export default LoadingLayout;
