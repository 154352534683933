import classNames from 'classnames';
import * as React from 'react';

import './stripe.scss';

interface HocProps {
  children: Array<any> | string | object | number | boolean,
  withLines?: boolean,
}

function Stripe({ children, withLines }: HocProps) {

  return (
    <div className={classNames(
      "Stripe-wrapper",
      {
        "Stripe-lines": withLines
      })}>
      {children}
    </div>
  );
}

export default Stripe;
