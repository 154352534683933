import * as React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from 'src/components/Layout/Layout';
import SubLinks from '../../components/SubLinks/SubLinks';

import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ActivityQueryInterface } from 'src/config/interfaces';
import { getActivity, updateActivity } from 'src/api/activities';
import useDisclosure from 'src/utils/useDisclosure';
import SelectProfile from 'src/components/SelectProfile/SelectProfile';
import { getPersonList } from 'src/api/person';
import ActivityInfo from 'src/components/ActivityInfo/ActivityInfo';
import LoadingLayout from 'src/components/Layout/Loading';
import ProfilesList from 'src/components/ProfilesList/ProfilesList';

function Activity() {
  const params = useParams();
  const { t } = useTranslation();
  const { data, isLoading }: ActivityQueryInterface = useQuery(['activity', params.activityId], () => getActivity(params.activityId || ''));
  const queryClient = useQueryClient();
  const selectStudentModal = useDisclosure();
  const selectTeacherModal = useDisclosure();
  const { data: studentsData } = useQuery('studentIds', () => getPersonList('student'));
  const { data: teachersData } = useQuery('teacherIds', () => getPersonList('teacher'));
  const studentIds = studentsData ? studentsData.values : [];
  const teacherIds = teachersData ? teachersData.values : [];

  const updateActivityMutation = useMutation(updateActivity, {
    onSuccess: () => {
      queryClient.invalidateQueries(['activity', params.activityId]);
    }
  })

  const teacherSubLinks = [
    {
      title: 'Overview',
      id: 'overview',
      url: `/management/teachers/teacher/${params.activityId}`,
    },
  ];

  const addToEvent = (type: string, id: string, userId?: string) => {
    const newEventData = {...data?.value};

    if (type === 'teacher') {
      newEventData.teacherIds = [
        ...(newEventData.teacherIds || []),
        id,
      ];
    }

    if (type === 'student') {
      newEventData.students = [
        {
          profileId: id,
          userId,
        },
        ...(newEventData.students || []),
      ];
    }

    delete newEventData.refUser;

    updateActivityMutation.mutate(newEventData);
  }

  const deleteFromEvent = (type: string, id: string) => {
    const newEventData = {...data?.value};

    if (type === 'teacher') {
      newEventData.teacherIds = [
        ...(newEventData.teacherIds || []).filter(i => i !== id)
      ];
    }

    if (type === 'student') {
      newEventData.students = [
        ...(newEventData.students || []).filter(i => i.profileId !== id),
      ];
    }

    delete newEventData.refUser;

    updateActivityMutation.mutate(newEventData);
  }

  return (
    <Layout>
      {selectStudentModal.isOpen && (
        <SelectProfile
          onHide={selectStudentModal.onClose}
          type='student'
          title="Select student"
          ids={studentIds}
          callBack={(profileId: string, userId: string) => addToEvent('student', profileId, userId)}
        />
      )}
      {selectTeacherModal.isOpen && (
        <SelectProfile
          onHide={selectTeacherModal.onClose}
          type='teacher'
          title="Select teacher"
          ids={teacherIds}
          callBack={(profileId: string) => addToEvent('teacher', profileId)}
        />
      )}
      <Container>
        {console.log(data)}
        <LoadingLayout isLoading={isLoading}>
          <Row>
            <Col xl={3}>
              <ActivityInfo activityData={data} />
            </Col>
            <Col xl={9}>
              <SubLinks sublinks={teacherSubLinks} />
            

              <ProfilesList
                type='teacher'
                title={t('teachers')}
                addPerson={selectTeacherModal.onOpen}
                deletePerson={deleteFromEvent}
                profileIds={data?.value?.teacherIds || []}
              />

              <ProfilesList
                type='student'
                title={t('students')}
                addPerson={selectStudentModal.onOpen}
                deletePerson={deleteFromEvent}
                profileIds={(data?.value?.students || []).map(i => i.profileId)}
              />

            </Col>
          </Row>
        </LoadingLayout>
      </Container>
    </Layout>
  );
}

export default Activity;
