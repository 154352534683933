const es = () => ({
search: 'Buscar', 
  headerSearchFormPlaceholder: 'Buscar usuarios, actividades…', 
  language: 'Idioma', 
  english: 'Inglés', 
  spanish: 'Español', 
 catalá: 'Catalá', 
  settings: 'Ajustes', 
  notifications: 'Notificaciones', 
  editProfile: 'Editar perfil', 
  chatWithAdmin: 'Chat con admin', 
  logout: 'Cerrar sesión', 
  allTeachers: 'Todos los docentes', 
  highLevel: 'Nivel alto', 
  regular: 'Regular', 
  teachers: 'Docentes', 
 
  selected: 'Seleccionar', 
  deselect: 'Quitar', 
  export: 'Exportar', 
  delete: 'Borrar', 
  import: 'Importar', 
  filter: 'Filtrar', 
  newTeacher: 'Nuevo docente', 
  name: 'Nombre', 
  activities: 'Actividades', 
  status: 'Estado', 
  created: 'Creado', 
  next: 'Siguiente', 
  previous: 'Anterior', 
  details: 'Detalles', 
  resultsInfo: 'Showing <strong>{{from}}</strong> to <strong>{{to}}</strong> of <strong>{{total}}</strong> results',  
  students: 'Alumnos', 
  newStudent: 'Nuevo alumno', 
  allStudents: 'Todos los alumnos', 
  mainParent: 'Tutor Principal', 
 
  allActivities: 'Todas las actividades',
  newEvent: 'Nuevo Evento', 
  newActivity: 'Nueva actividad', 
  documents: 'Documentos', 
  allDocuments: 'Todos los documentos', 
  newDocument: 'Nuevo documento', 

  allReports: 'Todos los reportes',
  reports: 'Reportes',
  
  email: 'Email', 
  phone: 'teléfono', 
  category: 'Catagoría', 
  regularSchool: 'Centro educativo',
  
  edit: 'Editar',
noDetails: 'Sin Detalles',
parent: 'Tutor',
loginForSchools: 'Iniciar Sesión Colegios',
signupForSchools: 'Registro Colegios',
contact: 'Contacto',
privacyPolicy: 'Política de Privacidad',

catalan: 'Català',

logIn: 'Iniciar Sesión',
toYourAccount: 'a tu cuenta',
password: 'Contraseña',
forgotPassword: 'Olvidé mi contraseña',
register: 'Registro',
resetPassword: 'Restaurar Contraseña',
passwordDontMatch: 'Las contraseñas no coinciden',
repeatPassword: 'Repite la contraseña',
forYourAccount: 'para tu cuenta',
backToLogin: 'Volver a Inicio de Sesión',
checkYourEmail: 'Revisa tu correo',

allUsers: 'Todos los usuarios',
users: 'Usuarios',
newParent: 'Nuevo Tutor',
newFamily: 'Nueva Familia',
parents: 'Tutores',
otherParents: 'Otro Tutor',
siblings: 'Hermanos',
children: 'Niños',
date: 'Fecha',
organizers: 'Organizadores',
teachingTeam: 'Equipo docente',
home: 'Inicio',

monday: 'Lunes',
tuesday:'Martes',
wednesday:'Miercoles',
thursday:'Jueves',
friday:'Viernes',
saturday:'Sabado',
sunday:'Domingo'
});

export default es;
