import { API_URL } from "src/config/api";
import { UserType } from "src/config/interfaces";

export let token:string;

export const setToken = (newToken: string) => {
  token = newToken;
}

export const authenticateUser = async () => {
  const res = await fetch(`${API_URL}/v1/auth/authenticate`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer: ${token}`,
    }
  });

  if (res.ok) {
    return res.json();
  }
  throw new Error('User in not authenticated');
}

export const getProfile = async () => {
  const res = await fetch(`${API_URL}/v1/auth/mergedprofile`, {
    headers: {
      Authorization: `Bearer: ${token}`,
    }
  });

  if (res.ok) {
    return res.json();
  }
  throw new Error('User in not authenticated');
}

export const createUser = async (userData: any, type: UserType) => {
  const res = await fetch(`${API_URL}/v1/object/user?orgId=organization-1&profiles=${type}`, {
    method: 'POST',
    body: JSON.stringify(userData),
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    }
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error('User is not created');
}

export const updateUser = async (userData: any) => {
  const res = await fetch(`${API_URL}/v1/object/user?orgId=organization-1`, {
    method: 'POST',
    body: JSON.stringify(userData),
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    }
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error('User is not updated');
}

export const getUserById = async (userId: string) => {

  if (!userId) 
    throw new Error('UserId is empty');

  const res = await fetch(`${API_URL}/v1/object/user/${userId}`, {
    headers: {
      Authorization: `Bearer: ${token}`,
    }
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error('User is not received');
}

export const uploadImageToUserById = async (userId: string, encodedImage: string) => {

  if (!userId) 
    throw new Error('UserId is empty');

  const res = await fetch(`${API_URL}/v1/action/user/avatar/replace/${userId}`, {
    method: 'POST',
    body: JSON.stringify({
      encodedImage,
    }),
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    },
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error('User is not received');
}
