import { API_URL } from "src/config/api";
import { token } from "./user";

export const createActivity = async (activityData: any): Promise<any> => {
  
  var finalActivityDataStr = '{"data":[]}';
  var finalActivityData = JSON.parse(finalActivityDataStr); 

  var name = activityData['name']
  var description = activityData['description']
  var teacherIds = activityData['teacherIds']
  var date = activityData['date']

  var studentsIdsString = '{"data":[]}'
  var studentsIds = JSON.parse(studentsIdsString);

  for (let i = 0; i < activityData['students'].length; i++){
    var tmp = activityData['students'][i].split(" ")
    studentsIds["data"].push({"profileId": tmp[0], "userId":tmp[1]})
  }

  console.log(studentsIds["data"])

  finalActivityData['data'].push({"name":name,"description":description,"date":date,teacherIds:teacherIds,students:studentsIds["data"]});

  console.log(finalActivityData["data"])

  console.log(JSON.stringify(finalActivityData["data"][0]))

  const res = await fetch(`${API_URL}/v1/object/activityevent`, {
    method: 'POST',
    body: JSON.stringify(finalActivityData["data"][0]),
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    }
  });

  if (res.ok) {
    alert("Actividad creada correctamente")
  }else{
    alert("Error al crear la actividad")
  }
};

function getNextDayOfTheWeek(dayName, excludeToday = false, weekNumber, refDate = new Date()) {
  const dayOfWeek = ["sun","mon","tue","wed","thu","fri","sat"]
                    .indexOf(dayName.slice(0,3).toLowerCase());
  if (dayOfWeek < 0) return;
  refDate.setHours(0,0,0,0);
  refDate.setDate(refDate.getDate() + +!!excludeToday + 
                  (dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7);
  refDate.setDate(refDate.getDate() + ( 7 * weekNumber))    
  return refDate;
}

export const createRecurrentActivity = async (activityData: any): Promise<any> => {

  var recurrentActivityDataStr = '{"identifier":"","activities":[]}';
  var recurrentActivityData = JSON.parse(recurrentActivityDataStr);
  var name = activityData['name']
  var description = activityData['description']
  var nextDate

  var teacherIds = activityData['teacherIds']
  //var hours = activityData['hours']
  //console.log(hours)
  
  var studentsIdsString = '{"data":[]}'
  var studentsIds = JSON.parse(studentsIdsString);

  for (let i = 0; i < activityData['students'].length; i++){
    var tmp = activityData['students'][i].split(" ")
    studentsIds["data"].push({"profileId": tmp[0], "userId":tmp[1]})
  }

  for (let i = 0; i < activityData['days'].length; i++) {
    for (let j = 0; j < activityData['repeated_weeks']; j++) {
      if (j === 0) {
        nextDate = getNextDayOfTheWeek(activityData['days'][i],false,j)
        recurrentActivityData['activities'].push({"name":name,"description":description,"date":nextDate,teacherIds:teacherIds,students:studentsIds["data"]});
      }else{
        nextDate = getNextDayOfTheWeek(activityData['days'][i],false,j)
        recurrentActivityData['activities'].push({"name":name,"description":description,"date":nextDate,teacherIds:teacherIds,students:studentsIds["data"]});
      }
    }
  }

  //console.log(recurrentActivityData)
  //console.log(recurrentActivityData['activities'])


  const res = await fetch(`${API_URL}/v1/object/recurrentactivityevent`, {
    method: 'POST',
    body: JSON.stringify(recurrentActivityData),
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    }
  });

  //console.log(res)

  if (res.ok) {
    alert("Actividad creada correctamente")
  }else{
    alert("Error al crear la actividad")
  }
};

export const updateActivity = async (acitivityData: any): Promise<any> => {
  const res = await fetch(`${API_URL}/v1/object/activityevent`, {
    method: 'POST',
    body: JSON.stringify(acitivityData),
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    }
  });

  if (res.ok) {
    console.log(res)
    return res.json();
  }

  throw new Error('Activity is not updated');
};

export const getActivity = async (activityId: string): Promise<any> => {

  const res = await fetch(`${API_URL}/v1/object/activityevent/${activityId}`, {
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    }
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error('Activity is not received');
};

export const getActivityList = async (): Promise<any> => {

  const res = await fetch(`${API_URL}/v1/object/list/ActivityEvent`, {
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    }
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error('Activities is not received');
};

export const getTeacherActivitiesToday = async (): Promise<any> => {

  const res = await fetch(`${API_URL}/v1/action/teacher/events/today`, {
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    }
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error('Activities is not received');
};
