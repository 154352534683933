import * as React from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';

import './layout.scss';

interface HocProps {
  children: Array<any> | string | object | number | boolean,
}

function Layout({ children }: HocProps) {

  return (
    <div>
      <Header />
      <Navigation />
      <section className='Layout-content'>
        {children}
      </section>
      <Footer />
    </div>
  );
}

export default Layout;
