import { useState } from 'react';

interface Disclosure {
  isOpen: boolean,
  onOpen: Function,
  onClose: Function,
}

export default function useDisclosure(open?: boolean): Disclosure {
  const [isOpen, setOpen] = useState(open || false);

  return {
    isOpen,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
  };
}
