import * as React from 'react';
import { NavLink } from 'react-router-dom';

import './subLinks.scss';

type Link = {
  id: number | string,
  title: string,
  url: string,
}

interface SubLinksInterface {
  sublinks: Array<Link>,
}

function SubLinks({
  sublinks
}: SubLinksInterface) {

  return (
    <ul className="SubLinks">
      {sublinks.map(link => (
        <li key={link.id}>
          <NavLink end to={link.url}>
            {link.title}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}

export default SubLinks;
