import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';
import Stack from 'react-bootstrap/Stack';
import { useTranslation } from "react-i18next";

import i18n from '../../config/i18n';

import NotificcationImg from '../../images/bell.svg';
import LanguageImg from '../../images/globe.svg';
import SettingsImg from '../../images/gear.svg';

import './headerMenu.scss';
import { useQueryClient } from 'react-query';
import { signOut } from 'firebase/auth';
import { auth } from 'src/api/firebase';
import { useNavigate } from 'react-router-dom';

function HeaderMenu() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const userProfile:any = queryClient.getQueryData('userProfile');

  const changeLanguage = (lng: string): Promise<any> => {
    window.localStorage.setItem('safetykids-language', lng);
    return i18n.changeLanguage(lng);
  }

  return (
    <div className='HeaderMenu'>
      <Row className='HeaderMenu-aligned'>
        <Col xl={1}>

          <Dropdown className='no-toggle'>
            <Dropdown.Toggle variant="light" id="language-header">
              <Image
                src={LanguageImg}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Header>{t('language')}</Dropdown.Header>
              <Dropdown.Item
                onClick={() => {
                  changeLanguage('en');
                }}
              >{t('english')}</Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  changeLanguage('es');
                }}
              >{t('spanish')}</Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  changeLanguage('ca');
                }}
              >{t('catalan')}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          
        </Col>
        <Col xl={1}>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="settings-header">{t('settings')}</Tooltip>}
          >
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                {...triggerHandler}
                className="d-inline-flex align-items-center"
              >
                <Image
                  ref={ref}
                  src={SettingsImg}
                />
              </Button>
            )}
          </OverlayTrigger>
        </Col>
        <Col xl={1}>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="notifications-header">{t('notifications')}</Tooltip>}
          >
            {({ ref, ...triggerHandler }) => (
              <Button
                variant="light"
                {...triggerHandler}
                className="d-inline-flex align-items-center"
              >
                <Image
                  ref={ref}
                  src={NotificcationImg}
                />
              </Button>
            )}
          </OverlayTrigger>
        </Col>
        <Col xl={5} className="HeaderMenu-user">
          <Stack direction='horizontal' className='full-width'>
            <div>
              <Image
                src={userProfile.user.avatar}
                roundedCircle
                className='HeaderMenu-user-image'
              />
            </div>
            <div style={{ flex: 1 }}>
              <Dropdown>
                <Dropdown.Toggle variant="light" id="user-header" className="no-padding">
                  <span className="HeaderMenu-username">
                    {t('manager')}
                  </span><br />
                  <span className="HeaderMenu-userinfo no-wrap">
                    {userProfile.user.firstName} {userProfile.user.lastName}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Header className='no-wrap'>{userProfile.user.firstName} {userProfile.user.lastName}</Dropdown.Header>
                  <Dropdown.Item>{t('editProfile')}</Dropdown.Item>
                  <Dropdown.Item>{t('chatWithAdmin')}</Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      signOut(auth);
                      navigate('/');
                    }}
                  >{t('logout')}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Stack>
        </Col>
      </Row>
    </div>
  );
}

export default HeaderMenu;
