import * as React from 'react';
import { Routes, Route } from "react-router-dom";

import './App.scss';

import './config/i18n';

import Auth from './components/Auth/Auth';
import Register from './pages/Register/Register';
import Login from './pages/Login/Login';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import NewPassword from './pages/NewPassword/NewPassword';
import Home from './pages/Home/Home';
import Management from './pages/Management/Management';
import Teachers from './pages/Teachers/Teachers';
import Teacher from './pages/Teacher/Teacher';
import Students from './pages/Students/Students';
import Activities from './pages/Activities/Activities';
import Documents from './pages/Documents/Documents';
import Parent from './pages/Parent/Parent';
import Student from './pages/Student/Student';
import Users from './pages/Users/Users';
import Activity from './pages/Activity/Activity';
import Reports from './pages/Reports/Reports'

// TODO: Replace with real Pages

function App() {
  return (
    <Routes>
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/new-password" element={<NewPassword />} />

      <Route path="/management/teachers/teacher/:teacherId" element={<Auth><Teacher /></Auth>}>
        <Route path=":tabId" element={<Auth><Teacher /></Auth>} />
      </Route>
      <Route path="/management/teachers/add" element={<Auth><div /></Auth>} />
      <Route path="/management/teachers" element={<Auth><Teachers /></Auth>}>
        <Route path=":type" element={<Auth><Teachers /></Auth>}>
          <Route path=":page" element={<Auth><Teachers /></Auth>} />
        </Route>
      </Route>

      <Route path="/management/students/student/:studentId" element={<Auth><Student /></Auth>}>
        <Route path=":tabId" element={<Auth><Student /></Auth>} />
      </Route>
      <Route path="/management/students/add" element={<Auth><div /></Auth>} />
      <Route path="/management/students" element={<Auth><Students /></Auth>}>
        <Route path=":type" element={<Auth><Students /></Auth>}>
          <Route path=":page" element={<Auth><Students /></Auth>} />
        </Route>
      </Route>

      <Route path="/management/users/user/:userId" element={<Auth><Student /></Auth>}>
        <Route path=":tabId" element={<Auth><Student /></Auth>} />
      </Route>
      <Route path="/management/users/add" element={<Auth><div /></Auth>} />
      <Route path="/management/users" element={<Auth><Users /></Auth>}>
        <Route path=":type" element={<Auth><Users /></Auth>}>
          <Route path=":page" element={<Auth><Users /></Auth>} />
        </Route>
      </Route>

      <Route path="/management/activities/activity/:activityId" element={<Auth><Activity /></Auth>}> {/* TODO: */}
        <Route path=":tabId" element={<Auth><Activity /></Auth>} />
      </Route>
      <Route path="/management/activities/add" element={<Auth><div /></Auth>} />
      <Route path="/management/activities" element={<Auth><Activities /></Auth>}>
        <Route path=":type" element={<Auth><Activities /></Auth>}>
          <Route path=":page" element={<Auth><Activities /></Auth>} />
        </Route>
      </Route>



      <Route path="/management/documents/document/:documentId" element={<Auth><Teacher /></Auth>}> {/* TODO: */}
        <Route path=":tabId" element={<Auth><Teacher /></Auth>} />
      </Route>
      <Route path="/management/documents/add" element={<Auth><div /></Auth>} />
      <Route path="/management/documents" element={<Auth><Documents /></Auth>}>
        <Route path=":type" element={<Auth><Documents /></Auth>}>
          <Route path=":page" element={<Auth><Documents /></Auth>} />
        </Route>
      </Route>

      <Route path="/management/parents/parent/:parentId" element={<Auth><Parent /></Auth>} />

      <Route path="/management/reports/" element={<Auth><Reports/></Auth>} />
      <Route path="/management/home" element={<Auth><Management /></Auth>} />
      
      <Route path="/" element={<Home />} />
      <Route element={<div />} />
    </Routes>
  );
}

export default App;
