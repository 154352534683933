import * as React from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import Layout from 'src/components/Layout/Layout';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { useTranslation, Trans } from "react-i18next";

import UserForm from '../../components/UserForm/UserForm';

import './teachers.scss';

import useDisclosure from 'src/utils/useDisclosure';
import { useQueries, useQuery, useQueryClient } from 'react-query';
import { getPerson, getPersonList } from 'src/api/person';
import { ProfileTable } from 'src/config/interfaces';
import ProfileData from 'src/components/ProfileData/ProfileData';
import LoadingLayout from 'src/components/Layout/Loading';

function Teachers() {
  const { type } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkedItems, setCheckedItems] = React.useState({});
  const newTeacherModal = useDisclosure();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery('teacherIds', () => getPersonList('teacher'));

  const teacherIds = data && data.values ? data.values : [];

  useQueries(
    teacherIds.map((id: string) => ({
      queryKey: ['teacher', id],
      queryFn: () => getPerson(id, 'teacher'),
    })),
  );

  const teachers: Array<ProfileTable | undefined> = teacherIds.map((id: string) => queryClient.getQueryData(['teacher', id]));

  const teachersMenu = [
    {
      type: 'link',
      name: t('allTeachers'),
      link: '/management/teachers/all',
    },
    {
      type: 'subLink',
      name: t('highLevel'),
      link: '/management/teachers/high',
    },
    {
      type: 'subLink',
      name: t('regular'),
      link: '/management/teachers/regular',
    },
  ];

  React.useEffect(() => {
    if (!type) {
      navigate('/management/teachers/all')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const checkIfAllChecked = (): boolean => {
    const checkedLength = Object.entries(checkedItems).filter(item => item[1] === true).length;
    const teacherLength = teachers.length;
    return checkedLength === teacherLength;
  }

  const getChecked = (): number => {
    return Object.entries(checkedItems).filter(item => item[1] === true).length;
  }

  const checkIfChecked = (): boolean => {
    const checkedLength = Object.entries(checkedItems).filter(item => item[1] === true).length;
    return checkedLength > 0;
  }

  const handleCheckAll = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (!target.checked) {
      setCheckedItems({});
    } else {
      const newCheckedItems = {};

      Object.entries(teachers).forEach((el) => {
        if (el[1]?.person?.userId) {
          newCheckedItems[el[1]?.person?.userId] = true;
        }
      });

      setCheckedItems(newCheckedItems);
    }
  }

  const uncheckAll = () => {
    setCheckedItems({});
  }

  const handleCheckItem = (id?: string) => {
    if (!id) {
      return ;
    }
    const newCheckedItems = {...checkedItems};

    if (newCheckedItems[id]) {
      delete newCheckedItems[id];
    } else {
      newCheckedItems[id] = true;
    }
    setCheckedItems(newCheckedItems);
  };

  return (
    <>
      {newTeacherModal.isOpen && (
        <UserForm
          onHide={newTeacherModal.onClose}
          type='teacher'
        />
      )}
      <Layout>
        <Container>
          <Row>
            <Col xl={2}>
              <menu className='SubNavigation'>
                <h5>{t('teachers')}</h5>
                <ul className='mt-4'>
                  {
                    teachersMenu.map((item) => (
                      <li key={item.link}>
                        <NavLink
                          to={item.link}
                          className={`SubNavigation-${item.type}`}
                        >
                          {item.name}
                        </NavLink>
                      </li>
                    ))
                  }
                </ul>
              </menu>
            </Col>
            <Col xl={10}>
              <LoadingLayout isLoading={isLoading}>
                <Row>
                  <Col>
                    <h3 className='bold'>
                      {t('allTeachers')}
                      
                      {teachers.length && (
                        <span className='Teachers-total'>{teachers.length}</span>
                      )}
                    </h3>
                  </Col>
                  <Col className='Table-actions'>
                    {checkIfChecked() ? (
                      <div>

                        <div className='Teachers-checkedInfo'>
                          <span>
                            {getChecked()} {t('selected')}
                          </span>
                          <button
                            className='Button Button-link'
                            onClick={uncheckAll}
                          >
                            {t('deselect')}
                          </button>
                        </div>

                        <button
                          className="Button"
                        >
                          ↗ {t('export')}
                        </button>

                        <button
                          className="Button Button-danger"
                        >
                          {t('delete')}
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="Button"
                        >
                          {t('import')}
                        </button>

                        <button
                          className="Button"
                        >
                          {t('filter')}
                        </button>

                        <button
                          className="Button Button-primary"
                          onClick={() => newTeacherModal.onOpen()}
                        >
                          + {t('newTeacher')}
                        </button>
                      </div>
                    )}
                  </Col>
                </Row>

                <Table borderless className='Teachers-table mt-4'>
                  <thead>
                    <tr>
                      <th
                        className='Teachers-table-checkbox'
                      >
                        <label className='Checkbox'>
                          <input
                            type="checkbox"
                            checked={checkIfAllChecked()}
                            onChange={handleCheckAll}
                          />
                          <span />
                        </label>
                      </th>
                      <th>{t('name')}</th>
                      <th className='Teachers-table-details' style={{ width: '30%' }} />
                      <th className='text-end'>{t('created')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      teachers.map((teacher) => {
                        const key = teacher?.person?.userId;
                        return (
                          <tr key={teacher?.person.userId}>
                            <td>
                              <label className='Checkbox'>
                                <input
                                  type="checkbox"
                                  checked={checkedItems[key || 0] === true}
                                  onChange={() => handleCheckItem(key)}
                                />
                                <span />
                              </label>
                            </td>
                            <td>
                              <ProfileData type="teacher" variant='short-profile' id={teacher?.person?.id || ''} />
                            </td>
                            <td>
                              <Link to={`/management/teachers/teacher/${teacher?.person?.id}`}>{t('details')}</Link>
                            </td>
                            <td className='gray text-end'>
                              {new Date(teacher?.person.createdAt || '').toLocaleDateString()}
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </Table>
                <div className='Teachers-table-info mt-5'>
                  <Row>
                    <Col>
                      <Trans
                        i18nKey="resultsInfo" // optional -> fallbacks to defaults if not provided
                        defaults={t('resultsInfo')} // optional defaultValue
                        values={{ from: 1, to: teachers.length, total: teachers.length, }}
                        components={{ strong: <strong /> }}
                      />
                    </Col>
                    <Col className='Table-page-btns'>
                      <button
                        className='Table-page-btn'
                        disabled
                      >
                        ← {t('previous')}
                      </button>
                      <button
                        disabled
                        className='Table-page-btn'
                      >
                        {t('next')} →
                      </button>
                    </Col>
                  </Row>
                </div>
              </LoadingLayout>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}

export default Teachers;
