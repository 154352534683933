import * as React from 'react';
import Modal from 'react-bootstrap/Modal';

import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { createFamily } from 'src/api/family';

interface FamilyProps {
  onHide: Function,
  title?: string,
}

function FamilyForm({
  onHide,
  title,
}: FamilyProps) {
  const { handleSubmit } = useForm({
    defaultValues: {
      orgId: 'organization-1',
    },
  });

  const profileQuery = useMutation(
    ({ orgId }: any) => createFamily(orgId),
    {
      onSuccess: () => {
        onHide();
      },
      onError: () => {
        window.alert('Error');
      }
    }
  );

  const onProfileCreate = (data: any) => {
    const userData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      avatar: "",
    };
    const profileData = {
      type: data.type,
      email: data.email,
      orgId: data.orgId,
    };

    profileQuery.mutate({ userData, profileData, type: data.type });
  };

  return (
    <Modal show={true} onHide={() => onHide()}>
      <form onSubmit={handleSubmit(onProfileCreate)}>
        <Modal.Header className='bold'>
          {title || 'Add family'}
        </Modal.Header>
        <Modal.Body>

          <p>New Family will be created</p>

        </Modal.Body>
        <Modal.Footer className='text-end'>
          <button type="button" className='Button' onClick={() => onHide()}>
            Cancel
          </button>
          {' '}
          <button type="submit" className='Button Button-primary'>
            Add family
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default FamilyForm;
