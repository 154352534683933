import React from "react";
import Lottie from 'react-lottie';

import * as animationData from '../../images/loader.json';

import './loader.scss';

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const Loader = () => (
  <div className="Loader">
  <Lottie options={defaultOptions}
    height={200}
    width={200}
  />
  </div>
);

export default Loader;
