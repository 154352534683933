import classNames from 'classnames';
import React from 'react';

import './badge.scss';

interface BadgeInterface {
  type?: 'success' | 'error' | 'info',
  children: Array<any> | string | object | number | boolean,
  className?: string,
}

function FormControl({
  type,
  children,
  className,
}: BadgeInterface) {
  return (
    <div className={classNames(className, 'Badge', `Badge-${type}`)}>
      {children}
    </div>
  );
}

export default FormControl;
