import { useQuery } from 'react-query';
import { getPerson } from 'src/api/person';
import { ProfileTable, UserType } from 'src/config/interfaces';

interface QueryInterface {
  data?: ProfileTable,
}

export default function useProfile(type: UserType, id: string) {
  const {
    data,
  }: QueryInterface = useQuery([type, id], () => getPerson(id, type));

  let name = 'Unknown';

  name = [data?.user.firstName, data?.user.lastName].join(' ').trim() || name;
  const userId = data?.user.id;

  return {
    name,
    userId,
  };
}
