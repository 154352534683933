import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import './homeFooter.scss';

function HomeFooter() {
  const { t } = useTranslation();

  return (
    <div className="HomeFooter-wrapper">
      &copy; Safety Kids Manager
      <span>&#183;</span>
      <Link to="/">{t('contact')}</Link>
      <span>&#183;</span>
      <Link to="/">{t('privacyPolicy')}</Link>
    </div>
  );
}

export default HomeFooter;
