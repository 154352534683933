import * as React from 'react';
import { useForm } from 'react-hook-form';
import FormControl from 'src/components/FormControl/FormControl';
import CenteredLayout from 'src/components/Layout/Centered';

function NewPassword() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const password = watch('password');

  const onCreatePassword = (data: Object) => {
    console.log(data);
  };

  return (
    <CenteredLayout>
      <form onSubmit={handleSubmit(onCreatePassword)} className="credentials-form">
        <FormControl
          label={"Password"}
          register={register}
          type={'password'}
          name={'password'}
          watch={watch}
          validation={{ required: true, minLength: 8, maxLength: 32 }}
        />
        
        <FormControl
          label={"Repeat Password"}
          register={register}
          type={'password'}
          name={'repeatPassword'}
          watch={watch}
          validation={{
            required: true,
            minLength: 8,
            maxLength: 32,
            validate: (repeatPassword: String): Boolean => repeatPassword === password,
          }}
          errors={            
            (errors.repeatPassword && <div className="form-error">Passwords should be identical</div>)
          }
        />

        <div className="form-control">
          <button type="submit">
            Set New Password
          </button>
        </div>
      </form>
    </CenteredLayout>
  );
}

export default NewPassword;
