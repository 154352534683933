import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import './userForm.scss';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { UserType } from 'src/config/interfaces';
import useProfile from 'src/utils/useProfile';
import { createUser } from 'src/api/user';

interface UserProps {
  onHide: Function,
  title?: string,
  type?: UserType,
  callBack?: Function,
  selectDependant?: UserType,
  selectedDependantId?: string,
  familyId?: string,
}

const userTypes = [
  'Teacher',
  'Student',
  'Parent',
  'User',
]

function UserForm({
  onHide,
  title,
  type,
  callBack,
  selectDependant,
  selectedDependantId,
  familyId,
}: UserProps) {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      orgId: 'organization-1',
      firstName: '',
      lastName: '',
      email: '',
      type: type || '',
      dependantId: selectedDependantId,
    },
  });
  const queryClient = useQueryClient();

  const dependantProfile = useProfile(selectDependant, selectedDependantId || '');

  const profileQuery = useMutation(
    ({ userData, type }: any) => createUser(userData, type),
    {
      onSuccess: (data) => {
        onHide();

        queryClient.invalidateQueries(`${type}Ids`);

        if (callBack) {
          callBack(data)
        }
      },
      onError: () => {
        window.alert('Error');
      }
    }
  );

  const onProfileCreate = (data: any) => {
    const userData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      avatar: "",
    };
    const profileData = {
      type: data.type,
      email: data.email,
      orgId: data.orgId,
      familyId,
    };

    if (selectDependant === 'student') {
      profileData['childIds'] = [selectedDependantId];
    } else {
      delete profileData['childIds'];
    }

    profileQuery.mutate({ userData, profileData, type: data.type });
  };

  return (
    <Modal show={true} onHide={() => onHide()}>
      <form onSubmit={handleSubmit(onProfileCreate)}>
        <Modal.Header className='bold'>
          {title || 'Add account'}
        </Modal.Header>
        <Modal.Body>

          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <input
              className='form-control'
              type="text" 
              placeholder=""
              required
              {...register('firstName', {
                required: true,
              })}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="surname">
            <Form.Label>Surname</Form.Label>
            <input
              className='form-control'
              type="text" 
              required
              placeholder=""
              {...register('lastName', {
                required: true,
              })}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Account Email</Form.Label>
            <input
              className='form-control'
              type="email" 
              placeholder=""
              {...register('email', {
                required: type !== 'student',
              })}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="role">
            <Form.Label>Choose a role</Form.Label>
            <select
              className='form-control'
              disabled={!!type}
              {...register('type', {
                required: true,
              })}
            >
              <option value=""></option>
              {userTypes.map(type => (
                <option value={type.toLowerCase()} key={type}>{type}</option>
              ))}
            </select>
          </Form.Group>

          {selectDependant && (
            <Form.Group className="mb-3" controlId="role">
              <Form.Label>Choose a {selectDependant}</Form.Label>
              <select
                className='form-control'
                disabled={!!type || !!selectDependant}
                {...register('dependantId', {
                  required: true,
                })}
              >
                <option value=""></option>
                <option value={selectedDependantId} key={selectedDependantId}>
                  {dependantProfile.name}
                </option>
              </select>
            </Form.Group>
          )}

        </Modal.Body>
        <Modal.Footer className='text-end'>
          <button type="button" className='Button' onClick={() => onHide()}>
            Cancel
          </button>
          {' '}
          <button type="submit" className='Button Button-primary'>
            Add account
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default UserForm;
