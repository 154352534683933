import { API_URL } from "src/config/api";
import { token } from "./user";

export const getReport = async (reportId: string): Promise<any> => {

    const res = await fetch(`${API_URL}/v1/object/report/${reportId}`, {
      headers: {
        Authorization: `Bearer: ${token}`,
        'Content-Type': 'application/json',
      }
    });
  
    if (res.ok) {
        return res.json();
    }
  
    throw new Error('Report is not received');
  };

export const getReportList = async (): Promise<any> => {

    const res = await fetch(`${API_URL}/v1/object/report/all`, {
        headers: {
        Authorization: `Bearer: ${token}`,
        'Content-Type': 'application/json',
        }
    });

    if (res.ok) {
        return res.json();
    }

throw new Error('Report is not received');
};