import React from 'react';
import { useQuery } from 'react-query';
import { useAuthState } from "react-firebase-hooks/auth";
import { authenticateUser, getProfile, setToken } from 'src/api/user';
import { auth } from "src/api/firebase";
import Loader from '../Loader/Loader';

interface AuthProps {
  children: Array<any> | string | object | number | boolean,
}

const Auth = ({ children }: AuthProps) => {
  const [user, loading, authError] = useAuthState(auth);
  const { isLoading: dataLoading, data: userData, error } = useQuery('userData', authenticateUser);
  const { isLoading, data, error: userProfileErorr } = useQuery('userProfile', getProfile, {
    enabled: !!userData,
  });

  React.useEffect(() => {
    if (user) {
      user.getIdToken()
        .then(setToken);
    }
  }, [user])

  if (loading || isLoading || dataLoading) {
    return (<Loader />);
  }

  if (!data || error || authError || userProfileErorr) {
    return <div>{authError || 'Ошибка'}</div>;
  }

  return (
    <>
      {children}
    </>
  )
};

export default Auth;
