import * as React from 'react';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InfoBlock from 'src/components/InfoBlock/InfoBlock';
import ActivityId from 'src/components/ActivityId/ActivityId';
import { useTranslation } from "react-i18next";
import Foldable from '../Foldable/Foldable';

import { ActivityInterface } from 'src/config/interfaces';
import ProfileDefaultImg from '../../images/profile-default.svg';
import formatDate from 'src/utils/formatDate';

interface ActivityInforInterface {
  activityData?: ActivityInterface,
}

function ActivityInfo({ activityData }: ActivityInforInterface) {
  const { t } = useTranslation();

  if (!activityData) return null;

  return (
    <div className='User-info'>
      <h3 className='bolder'>
        {activityData?.value?.name}
      </h3>
      <div className='User-info-big gray'>
        <Row style={{ alignItems: 'center' }}>
          <Col>
            <Image
              src={activityData?.value?.image || ProfileDefaultImg}
              roundedCircle
              className='Profile-user-image'
            />
          </Col>
          <Col md={10}>
            <div>
              {t('activity')}
            </div>
          </Col>
        </Row>
      </div>
      <Foldable
        isOpen
        legend={t('details')}
        actions={
          (
            <button className='Button Button-link-primary'>
              {t('edit')}
            </button>
          )
        }
      >
        <div className="User-info-description">
        {activityData?.value?.description}
        </div>
        <InfoBlock
          label={t('date')}
        >
          {formatDate(activityData?.value?.date).fullDate()}
        </InfoBlock>

        <ActivityId
          label={t('identifier')}
        >
          {activityData?.value?.identifier}
        </ActivityId>

      </Foldable>
    </div>
  );
}

export default ActivityInfo;
