import classNames from 'classnames';
import React, { useState } from 'react';

interface FormControlInterface {
  register: Function,
  watch: Function,
  label?: Array<any> | string | object | number | boolean,
  errors?: Array<any> | string | object | number | boolean,
  type: string,
  name: string,
  className?: string,
  placeholder?: string,
  validation?: object,
  disabled?: boolean,
}

function FormControl({
  register,
  label,
  type,
  name,
  validation,
  watch,
  errors,
  placeholder,
  className,
  disabled
}: FormControlInterface) {
  const value = watch(name);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={classNames(className, 'formControl', {
      focused: value || isFocused,
    })}>
      {label && (
        <label htmlFor={name}>
          {label}
        </label>
      )}
      <input
        id={name}
        type={type}
        {...register(name, validation)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder}
        disabled={disabled}
      />
      {errors && errors[name] && (
        <div className='floating-error'>
          {errors[name].message}
        </div>
      )}
    </div>
  );
}

export default FormControl;
