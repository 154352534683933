import * as React from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import Layout from 'src/components/Layout/Layout';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import { useTranslation, Trans } from "react-i18next";

import Badge from '../../components/Badge/Badge';

import CalendarImg from '../../images/calendar.svg';
import useDisclosure from 'src/utils/useDisclosure';
import NoResults from 'src/components/NoResults/NoResults';

interface StudentTable {
  id: number,
  name: string,
  mainParent: string,
  status: 'Pending' | 'Approved',
  created: string,
}

const student = (index: number): StudentTable => ({
  id: index,
  name: 'Santiago Marín Álvarez',
  mainParent: 'María Ramos Bonito',
  status: index === 10 ? 'Pending' :'Approved',
  created: '22/01/2022',
});

function Documents() {
  const { type } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkedItems, setCheckedItems] = React.useState({});
  const newActivityModal = useDisclosure();

  const activitiesMenu = [
    {
      type: 'link',
      name: t('allDocuments'),
      link: '/management/documents/all',
    },
  ];

  const activities: Array<StudentTable> = [];
  
  for (let i = 0; i <= 0; i++) {
    if (i) activities.push(student(i));
  }

  React.useEffect(() => {
    if (!type) {
      navigate('/management/documents/all')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const checkIfAllChecked = (): boolean => {
    const checkedLength = Object.entries(checkedItems).filter(item => item[1] === true).length;
    const studentLength = activities.length;
    return checkedLength === studentLength;
  }

  const getChecked = (): number => {
    return Object.entries(checkedItems).filter(item => item[1] === true).length;
  }

  const checkIfChecked = (): boolean => {
    const checkedLength = Object.entries(checkedItems).filter(item => item[1] === true).length;
    return checkedLength > 0;
  }

  const handleCheckAll = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (!target.checked) {
      setCheckedItems({});
    } else {
      const newCheckedItems = {};

      Object.entries(activities).forEach((el) => {
        newCheckedItems[el[1].id] = true;
      });

      setCheckedItems(newCheckedItems);
    }
  }

  const uncheckAll = () => {
    setCheckedItems({});
  }

  const handleCheckItem = (id: number) => {
    const newCheckedItems = {...checkedItems};

    if (newCheckedItems[id]) {
      delete newCheckedItems[id];
    } else {
      newCheckedItems[id] = true;
    }
    setCheckedItems(newCheckedItems);
  };

  return (
    <>
      <Layout>
        <Container>
          <Row>
            <Col xl={2}>
              <menu className='SubNavigation'>
                <h5>{t('documents')}</h5>
                <ul className='mt-4'>
                  {
                    activitiesMenu.map((item) => (
                      <li key={item.link}>
                        <NavLink
                          to={item.link}
                          className={`SubNavigation-${item.type}`}
                        >
                          {item.name}
                        </NavLink>
                      </li>
                    ))
                  }
                </ul>
              </menu>
            </Col>
            <Col xl={10}>
              <Row>
                <Col>
                  <h3 className='bold'>
                    {t('allDocuments')}
                    
                    {activities.length > 0 && (
                      <span className='Teachers-total'>{activities.length}</span>
                    )}
                  </h3>
                </Col>
                <Col className='Table-actions'>
                  {checkIfChecked() ? (
                    <div>

                      <div className='Teachers-checkedInfo'>
                        <span>
                          {getChecked()} {t('selected')}
                        </span>
                        <button
                          className='Button Button-link'
                          onClick={uncheckAll}
                        >
                          {t('deselect')}
                        </button>
                      </div>

                      <button
                        className="Button"
                      >
                        ↗ {t('export')}
                      </button>

                      <button
                        className="Button Button-danger"
                      >
                        {t('delete')}
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="Button"
                      >
                        {t('import')}
                      </button>

                      <button
                        className="Button"
                      >
                        {t('filter')}
                      </button>

                      <button
                        className="Button Button-primary"
                        onClick={() => newActivityModal.onOpen()}
                      >
                        + {t('newDocument')}
                      </button>
                    </div>
                  )}
                </Col>
              </Row>
              {activities.length ? (
                <>
                <Table borderless className='Teachers-table mt-4'>
                  <thead>
                    <tr>
                      <th
                        className='Teachers-table-checkbox'
                      >
                        <label className='Checkbox'>
                          <input
                            type="checkbox"
                            checked={checkIfAllChecked()}
                            onChange={handleCheckAll}
                          />
                          <span />
                        </label>
                      </th>
                      <th>{t('name')}</th>
                      <th>{t('mainParent')}</th>
                      <th className='Teachers-table-details' />
                      <th className='Teachers-table-calendar' />
                      <th>{t('status')}</th>
                      <th>{t('created')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      activities.map((student) => (
                        <tr key={student.id}>
                          <td>
                            <label className='Checkbox'>
                              <input
                                type="checkbox"
                                checked={checkedItems[student.id] === true}
                                onChange={() => handleCheckItem(student.id)}
                              />
                              <span />
                            </label>
                          </td>
                          <td>
                            {student.name}
                          </td>
                          <td className='gray'>
                            <Link to={`/management/activities/student/${student.id}`}>{student.mainParent}</Link>
                          </td>
                          <td>
                            <Link to={`/management/activities/student/${student.id}`}>{t('details')}</Link>
                          </td>
                          <td>
                            <Image src={CalendarImg} />
                          </td>
                          <td>
                            <Badge
                              type={student.status === 'Approved' ? "success" : 'info'}
                            >
                              {student.status}
                            </Badge>
                          </td>
                          <td className='gray'>
                            {student.created}
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                <div className='Teachers-table-info mt-5'>
                  <Row>
                    <Col>
                      <Trans
                        i18nKey="resultsInfo" // optional -> fallbacks to defaults if not provided
                        defaults={t('resultsInfo')} // optional defaultValue
                        values={{ from: 1, to: 2, total: 2, }}
                        components={{ strong: <strong /> }}
                      />
                    </Col>
                    <Col className='Table-page-btns'>
                      <button
                        className='Table-page-btn'
                        disabled
                      >
                        ← {t('previous')}
                      </button>
                      <button
                        disabled
                        className='Table-page-btn'
                      >
                        {t('next')} →
                      </button>
                    </Col>
                  </Row>
                </div>
              </>
              ) : (
                <NoResults
                  title="Add first document"
                  action={(
                    <button
                      className="Button Button-primary"
                      onClick={() => newActivityModal.onOpen()}
                    >
                      + {t('newDocument')}
                    </button>
                  )}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </NoResults>
              )}
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}

export default Documents;
