import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import HomeFooter from 'src/components/HomeFooter/HomeFooter';
import HomeHeader from 'src/components/HomeHeader/HomeHeader';
import Stripe from 'src/components/Layout/Stripe';

import LogoImg from '../../images/logo.svg';

import './home.scss';



function Home() {

  return (
    <Stripe>
      <Container>
        <Stack className="Home-wrapper">
          <HomeHeader />
          <Stack direction='horizontal' className="Home-content">
            <div>
              <img src={LogoImg} alt="Safety Kids Manager" />
            </div>
            <div>
              <h1>
              <span>Safety Kids Manager</span>
              <br />
              Pon la SEGURIDAD en el lugar que se merece
              </h1>
              <p>
              Te damos la bienvenida a la digitalización de tu centro. Safety Kids Manager es la aplicación de gestión y seguridad infantil que estabas esperando.
              Garantiza la entrega segura del menor y olvídate de cualquier error lamentable.
              Tranquilidad para alumnos, centros y familias con nuestra solución ante custodias.
              Eliminamos el papel y la difícil gestión de una autorización para recoger a niños y niñas.
              </p>
              </div>
          </Stack>
          <HomeFooter />
        </Stack>
      </Container>
    </Stripe>
  );
}

export default Home;
