import { useQuery } from "react-query";
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { getPerson } from "src/api/person";
import { QueryInterface, UserType } from "src/config/interfaces";
import classNames from "classnames";

import ProfileDefaultImg from '../../images/profile-default.svg';

interface ProfileDataInterface {
  type: UserType,
  id: string | '',
  variant?: 'name' | 'profile' | 'short-profile',
  onClick?: Function,
}

const ProfileData = ({ type, id, variant = 'name', onClick }: ProfileDataInterface) => {
  const { data, isLoading }: QueryInterface = useQuery([type, id], () => getPerson(id, type));

  const name = [data?.user?.firstName, data?.user?.lastName].join(' ').trim() || 'Unknown';

  if (isLoading) return <>{name}</>;

  if (variant === 'short-profile') {
    return (
      <Row
        className={classNames('valign-middle',
        {
          clickable: !!onClick,
        }
        )}
        type={!!onClick && 'button'}
        onClick={() => {
          if (onClick) {
            onClick(id, data?.user.id);
          }
        }}
      >
        <Col style={{ width: '3rem', flex: 0 }}>
          <Image
            src={data?.user.avatar || ProfileDefaultImg}
            roundedCircle
            className='Table-user-image'
          />
        </Col>
        <Col>
          <div>
            {name}
          </div>
        </Col>
      </Row>
    )
  }

  return <>{name}</>;
};

export default ProfileData;
