const ca = () => ({
search: 'Cerca', 
  headerSearchFormPlaceholder: 'Cerca d\'usuaris, transaccions, activitats', 
  language: 'Language', 
  english: 'Anglès', 
  spanish: 'Espanyol', 
  settings: 'Configuració', 
  notifications: 'Notificacions', 
  editProfile: 'Edita el perfil', 
  chatWithAdmin: 'Xateja amb l\'admi', 
  logout: 'Tancar sessió', 
  allTeachers: 'Tots els professors', 
  highLevel: 'Alt nivell', 
  regular: 'Regular', 
  teachers: 'professors', 
 
  selected: 'seleccionat', 
  deselect: 'deseleccioneu', 
  export: 'Exporta', 
  delete: 'Suprimeix', 
  import: 'Importar', 
  filter: 'Filtre', 
  newTeacher: 'professors nou', 
  name: 'Nom', 
  activities: 'Activitats', 
  status: 'Estat', 
  created: 'Creat', 
  next: 'Pròxim', 
  previous: 'Anterior', 
  details: 'Detalls', 
  resultsInfo: 'Mostrant <strong>{{from}}</strong> a<strong>{{to}}</strong> de <strong>{{total}}</strong> resultats', 
 
  students:'Estudiants', 
  newStudent: 'Estudiant Nou', 
  allStudents: 'Tots els Estudiants', 
  mainParent: 'Tutor', 
 
  allActivities: 'Totes les Activitats', 
  newActivity: 'Activitat nova', 
  documents: 'Documents', 
  allDocuments: 'Tots els documents', 
  newDocument: 'Nou document', 
 
  email: 'Correu electrònic', 
  phone: 'Telèfon', 
  category: 'categoria', 
  regularSchool: 'Escola', 
  
  edit: 'Edita',
noDetails: 'Sense Detalls',
parent: 'Tutor',
loginForSchools: 'Inici de Sessió Col·legis',
signupForSchools: 'Registre Col·legis',
contact: 'Contacte',
privacyPolicy: 'Política de Privadesa',

catalan: 'Català',

logIn: 'Iniciar Sessió',
toYourAccount: 'al teu compte',
password: 'Contrasenya',
forgotPassword: 'He oblidat la contrasenya',
register: 'Registre',
resetPassword: 'Restableix la Contrasenya',
passwordDontMatch: 'Les contrasenyes no coincideixen',
repeatPassword: 'Repetiu la contrasenya',
forYourAccount: 'per al teu compte',
backToLogin: 'Torna a la Pàgina d\'inici de sessió',
checkYourEmail: 'Comprova el teu correu electrònic.',

allUsers: 'Tots els usuaris',
users: 'Usuaris',
newParent: 'Nou Tutor',
newFamily: 'Nova família',
parents: 'Tutores',
otherParents: 'Altre Tutor',
siblings: 'Germans',
children: 'Nens',
date: 'Data',
organizers: 'Organitzadors',
teachingTeam: 'Equip docent',
home: 'Inici',
});

export default ca;
