import classNames from 'classnames';
import * as React from 'react';
import Stack from 'react-bootstrap/Stack';

import "./centered.scss";

interface LayoutContentInterface {
  children: Array<any> | string | object | number | boolean,
  withFooter?: boolean,
}

function CenteredLayout({ children, withFooter  }: LayoutContentInterface) {
  return (
    <Stack>
      <div className={classNames("centered-layout-wrapper", {
        'centered-layout-withfooter': withFooter,
      })}>
        <div className="centered-layout">
          {children}
        </div>
      </div>
    </Stack>
  );
};

export default CenteredLayout;
