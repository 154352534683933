import React from 'react';
import classNames from 'classnames';
import useDisclosure from 'src/utils/useDisclosure';
import Image from 'react-bootstrap/Image';

import ArrowRightImg from '../../images/arrow-right.svg';
import ArrowDownImg from '../../images/arrow-down.svg';

interface FoldableTableRowProps {
  cells: Array<any> | string | object | number | boolean,
  data: Array<any> | string | object | number | boolean,
  totalRows?: number,
}

const FoldableTableRow = ({ cells, data, totalRows }: FoldableTableRowProps) => {
  const expose = useDisclosure();

  const toggleExposed = () => {
    if (expose.isOpen) {
      expose.onClose();
    } else {
      expose.onOpen();
    }
  }

  return (
    <>
      <tr
        className={classNames("Teachers-table-colored-links valign-middle FoldableTableRow", {
          'FoldableTableRow-exposed': expose.isOpen,
        })}
        role="button"
        onClick={toggleExposed}
      >
        <td className='FoldableTableRow-arrow'>
          <Image
            src={expose.isOpen ? ArrowDownImg : ArrowRightImg}
          />
        </td>
        {cells}
      </tr>
      {expose.isOpen && (
        <tr>
          <td colSpan={(totalRows || 0) + 1} className='text-start'>
            {data}
          </td>
        </tr>
      )}
    </>
  )
}

export default FoldableTableRow;
