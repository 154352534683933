import { API_URL } from "src/config/api";
import { SingleFamilyInterface } from "src/config/interfaces";
import { token } from "./user";

export const createFamily = async (orgId: string): Promise<any> => {
  const res = await fetch(`${API_URL}/v1/object/family`, {
    method: 'POST',
    body: JSON.stringify({orgId}),
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    }
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error('Family is not created');
};

export const updateFamily = async (familyData: SingleFamilyInterface): Promise<any> => {
  const res = await fetch(`${API_URL}/v1/object/family`, {
    method: 'POST',
    body: JSON.stringify(familyData),
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    }
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error('Family is not updated');
};

export const getFamiliesById = async (orgId: string): Promise<any> => {
  const res = await fetch(`${API_URL}/v1/object/family/${orgId}`, {
    headers: {
      Authorization: `Bearer: ${token}`,
    }
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error('Families by org are not retrieved');
};
