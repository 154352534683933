import * as React from 'react';
import InfoBlock from 'src/components/InfoBlock/InfoBlock';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";

import Tag from '../Tag/Tag';
import Foldable from '../Foldable/Foldable';

import './userInfo.scss';
import { ProfileTable } from 'src/config/interfaces';
import ProfileDefaultImg from '../../images/profile-default.svg';
import { useMutation, useQueryClient } from 'react-query';
import { uploadImageToUserById } from 'src/api/user';

interface UserInfoInterface {
  userData?: ProfileTable,
}

type UserUploadType = {
  userId: string,
  encodedImage: string,
}

function UserInfo({ userData }: UserInfoInterface) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const updateUserImage = useMutation(({
    userId,
    encodedImage,
  }: UserUploadType) => uploadImageToUserById(userId, encodedImage), {
    onSuccess: () => {
      queryClient.invalidateQueries([userData?.person.type, userData?.person.id]);
    }
  });

  if (!userData) return null;

  const fileUpload = ({ target }: React.BaseSyntheticEvent) => {
    new Promise((resolve, reject) => {
      const file = target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function(readFile) {
        const encodedFile = reader?.result;
        resolve(encodedFile);
      };
      reader.onerror = function() {
        console.log("couldn't read the file");
        reject()
      };
    }).then((base64File: string) => {
      if (!!base64File) {
        updateUserImage.mutate({
          userId: userData.user.id,
          encodedImage: base64File,
        });
      }
    });

    target.value = '';
  };

  return (
    <div className='User-info'>
      <h3 className='bolder'>
        {userData.user.firstName}
        {' '}
        {userData.user.lastName}
      </h3>

      <div className='User-info-big gray'>
        <Row style={{ alignItems: 'center' }}>
          <Col md={2}>
            <label className='edit-image'>
              <input type="file" className='d-none' onChange={fileUpload} />
              <Image
                src={userData?.user.avatar || ProfileDefaultImg}
                roundedCircle
                className='Profile-user-image'
              />
            </label>
          </Col>
          <Col md={10}>
            <div className='no-wrap'>
              <a className='gray' href={`mailto:${userData.user.email}`}>{userData.user.email}</a>
            </div>
            <div className='text-uppercase'>
              {t(userData.person.type)}
            </div>
          </Col>
        </Row>
      </div>
      <Foldable
        isOpen
        legend={t('details')}
        actions={
          (
            <button className='Button Button-link-primary'>
              {t('edit')}
            </button>
          )
        }
      >
        <div className="User-info-description">
          {userData.person.description}
        </div>
        <InfoBlock
          label={t('regularSchool')}
        >
          School #2324
        </InfoBlock>

        <InfoBlock
          label={t('language')}
        >
          English (United States)
        </InfoBlock>

        <Row>
          <Col>
            <InfoBlock
              label={t('email')}
            >
              No Details
            </InfoBlock>
          </Col>
          <Col>
            <InfoBlock
              label={t('phone')}
            >
              +1053423300
            </InfoBlock>
          </Col>
        </Row>

        <Row>
          <Col>
            <InfoBlock
              label={t('email')}
            >
              {t('noDetails')}
            </InfoBlock>
          </Col>
          <Col>
            <InfoBlock
              label={t('phone')}
            >
              +1053423300
            </InfoBlock>
          </Col>
        </Row>

        <InfoBlock
          label={t('category')}
        >
          {['art', 'language', 'it', 'photo', 'business'].map((tag, index) => {
            const tags = ['green', 'purple', 'blue', 'orange', 'red'];

            return (
              <Tag
                key={index}
                type={tags[index]}
              >
                {tag}
              </Tag>
            )
          })}
        </InfoBlock>

      </Foldable>
    </div>
  );
}

export default UserInfo;
