import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";

import SearchImg from '../../images/search.svg';

import './searchForm.scss';

function SearchForm() {
  const { t } = useTranslation();
  return (
    <div className='SearchForm d-none'>
      <Row>
        <Col xl={1} className='SearchForm-icon'>
          <img src={SearchImg} alt={t('search')} />
        </Col>
        <Col xl={11}>
          <form>
            <input required type="search" placeholder={t('headerSearchFormPlaceholder')} />
          </form>
        </Col>
      </Row>
    </div>
  );
}

export default SearchForm;
