import { API_URL } from "src/config/api";
import { UserType } from "src/config/interfaces";
import { createUser, getUserById, token } from "./user";

const typeToPath = {
  parent: 'parentprofile',
  student: 'studentprofile',
  teacher: 'teacherprofile',
};

const typeToProfile = {
  parent: 'ParentProfile',
  student: 'StudentProfile',
  teacher: 'TeacherProfile',
};

export const createPerson = async (userData: any, personData: any, type: UserType): Promise<any> => {
  const user = await createUser(userData, type);

  const { id } = user;

  const res = await fetch(`${API_URL}/v1/object/${typeToPath[type || '']}`, {
    method: 'POST',
    body: JSON.stringify({userId: id, ...personData}),
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    }
  });

  if (res.ok) {
    const personRes = await res.json();

    // await updateUser({
    //   ...user,
    //   userAuthIds: [
    //     ...(user.userAuthIds || []),
    //     personRes.id,
    //   ]
    // });

    return personRes;
  }

  throw new Error('Person is not created');
};

export const updatePerson = async (personData: any, type: UserType): Promise<any> => {
  const res = await fetch(`${API_URL}/v1/object/${typeToPath[type || '']}`, {
    method: 'POST',
    body: JSON.stringify({...personData}),
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    }
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error('Person is not updated');
};

export const getPerson = async (personId: string, type: UserType): Promise<any> => {

  const res = await fetch(`${API_URL}/v1/object/${typeToPath[type || '']}/${personId}`, {
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    }
  });

  if (res.ok) {
    const personData = await res.json();

    const userData = await getUserById(personData?.value.userId);

    return {
      person: personData.value,
      user: userData.value,
    };
  }

  throw new Error('Person is not received');
};

export const getPersonList = async (type: UserType): Promise<any> => {

  const res = await fetch(`${API_URL}/v1/object/list/${typeToProfile[type || '']}`, {
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    }
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error('Person is not received');
};

export const addStudentToParent = async (parentId: string, studentId: string): Promise<any> => {

  const res = await fetch(`${API_URL}/v1/action/parent/linkchild/${parentId}/${studentId}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer: ${token}`,
      'Content-Type': 'application/json',
    }
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error('Student is not added to parent');
};
