import React from 'react';

import './noResults.scss';

interface NoResultsProps {
  title: string,
  children: Array<any> | string | object | number | boolean,
  action?: Array<any> | string | object | number | boolean,
}

const NoResults = ({ children, title, action }: NoResultsProps) => {
  return (
    <div className='NoResults-wrapper'>
      <div className='NoResults-content'>
        <h5 className='text-center'>{title}</h5>
        {children}
        {action && (
          <div className='mt-4 text-center NoResults-text'>
            {action}
          </div>
        )}
      </div>
    </div>
  )
};

export default NoResults;
