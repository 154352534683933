import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import './homeHeader.scss';

function HomeHeader() {
  const { t } = useTranslation();

  return (
    <div className="HomeHeader-wrapper">
      <Link to='/login' className='HomeHeader-signup-btn'>{t('loginForSchools')}</Link>
    </div>
  );
}

export default HomeHeader;
