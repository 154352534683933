import classNames from 'classnames';
import React from 'react';

import './tag.scss';

interface TagInterface {
  type?: 'green' | 'purple' | 'red' | 'orange' | 'blue' | string,
  children: Array<any> | string | object | number | boolean,
  className?: string,
}

function Tag({
  type,
  children,
  className,
}: TagInterface) {
  return (
    <div className={classNames(className, 'Tag', `Tag-${type}`)}>
      {children}
    </div>
  );
}

export default Tag;
