import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';

import { UserType } from 'src/config/interfaces';
import ProfileData from '../ProfileData/ProfileData';

interface UserProps {
  onHide: Function,
  title?: string,
  type?: UserType,
  callBack?: Function,
  ids: Array<string>,
}

function SelectProfile({
  onHide,
  title,
  type,
  callBack,
  ids = [],
}: UserProps) {
  
  return (
    <Modal show={true} onHide={() => onHide()}>
      <Modal.Header className='bold'>
        {title || 'Select account'}
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {ids.map((id) => (
            <ListGroup.Item
              style={{ cursor: 'pointer' }}
              key={id}
            >
              <ProfileData onClick={(profileId: string, userId: string) => {
                if (callBack)
                  callBack(profileId, userId);
                  onHide();
              }} variant='short-profile' type={type} id={id} />
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer className='text-end'>
        <button type="button" className='Button' onClick={() => onHide()}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default SelectProfile;
