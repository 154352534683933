import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { createActivity } from 'src/api/activities';
import { ActivityType, UserInterface } from 'src/config/interfaces';
import { getPerson, getPersonList } from 'src/api/person';
import LoadingLayout from '../Layout/Loading';

interface ActivityProps {
  onHide: Function,
  title?: string,
}

function ActivityForm({
  onHide,
  title,
}: ActivityProps) {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      description: '',
      date: '',
      teacherIds: [],
      students: [],
    },
  });
  const queryClient = useQueryClient();


  const { data: teacherData, isLoading: teacherLoading } = useQuery('teachersIds', () => getPersonList("teacher"));
  const teachersIds = teacherData && teacherData.values ? teacherData.values : [];
  useQueries(
    teachersIds.map((id: string) => ({
      queryKey: ['teacher', id],
      queryFn: () => getPerson(id,"teacher"),
    })),
  );
  const teachers: Array<UserInterface> = teachersIds.map((id: string) => queryClient.getQueryData(['teacher', id]));
  
  const { data: studentsData, isLoading: studentsLoading } = useQuery('studentIds', () => getPersonList("student"));
  const studentsIds = studentsData && studentsData.values ? studentsData.values : [];
  
  useQueries(
    studentsIds.map((id: string) => ({
      queryKey: ['student', id],
      queryFn: () => getPerson(id,"student"),
    })),
  );
  
  const students: Array<UserInterface> = studentsIds.map((id: string) => queryClient.getQueryData(['student', id]));


  const activityQuery = useMutation(
    (data: ActivityType) => createActivity(data),
    {
      onSuccess: (data) => {
        onHide();

        queryClient.invalidateQueries(`activityIds`);
      },
      onError: () => {
        window.alert('Error');
      }
    }
  );

  const onActivityCreate = (data: any) => {
    activityQuery.mutate(data);
  };

  return (
    <Modal show={true} onHide={() => onHide()}>
      <form onSubmit={handleSubmit(onActivityCreate)}>
        <Modal.Header className='bold'>
          {title || 'Add Event'}
        </Modal.Header>
        <Modal.Body>

          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Event name</Form.Label>
            <input
              className='form-control'
              type="name" 
              placeholder=""
              {...register('name', {
                required: true,
              })}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Short description</Form.Label>
            <textarea
              className='form-control' 
              placeholder=""
              {...register('description', {
                required: true,
              })}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="category">
            <Form.Label>Date</Form.Label>
            <input
              className='form-control'
              type="datetime-local" 
              placeholder=""
              {...register('date', {
                required: true,
              })}
            />
          </Form.Group>

          <Form.Label>Profesores</Form.Label>
          <LoadingLayout isLoading={teacherLoading}>
            <Form.Group className="mb-3" controlId="repeated_weeks">
              {teachers?.map((teach: UserInterface, idx: number) => {
                const teacher = teach;
                if (!teacher) return null;
                return(
                  <>
                  <div>
                    <input
                      type="checkbox" 
                      id={teacher["person"]["id"]}
                      value={teacher["person"]["id"]}
                      {...register('teacherIds', {
                        required: false,
                      })}
                    ></input>
                    <label className='days_label'>{teacher["user"]["firstName"]}</label>
                    <label className='days_label'>{teacher["user"]["lastName"]}</label>
                  </div>
                  </>
                )
              })}
            </Form.Group>
          </LoadingLayout>

          <Form.Label>Alumnos</Form.Label>
          <LoadingLayout isLoading={studentsLoading}>
            <Form.Group className="mb-3" controlId="repeated_weeks">
              {students?.map((stud: UserInterface, idx: number) => {
                const student = stud;
                if (!student) return null;
                return(
                  <>
                  <div>
                    <input
                      type="checkbox" 
                      id={student["person"]["id"]}
                      value={student["person"]["id"] +" "+ student["user"]["id"]}
                      {...register('students', {
                        required: false,
                      })}
                    ></input>
                    <label className='days_label'>{student["user"]["firstName"]}</label>
                    <label className='days_label'>{student["user"]["lastName"]}</label>
                  </div>
                  </>
                )
              })}
            </Form.Group>
          </LoadingLayout>

        </Modal.Body>
        <Modal.Footer className='text-end'>
          <button className='Button' type="button" onClick={() => onHide()}>
            Cancel
          </button>
          {' '}
          <button className='Button Button-primary' type="submit">
            Add activity
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default ActivityForm;
