import * as React from 'react';

interface ActivityIdInterface {
  label: any,
  children: Array<any> | string | object | number | boolean | any,
}

function ActivityId({ label, children }: ActivityIdInterface) {

  return (
    <div className='mt-4'>
      <div className='gray'>
        {label}
      </div>
      <div >{children}</div>
    </div>
  );
}

export default ActivityId;
