const en = () => ({
  search: 'Search',
  headerSearchFormPlaceholder: 'Search for users, transactions, events',
  language: 'Language',
  english: 'English',
  spanish: 'Espanol',
  settings: 'Settings',
  notifications: 'Notifications',
  editProfile: 'Edit profile',
  chatWithAdmin: 'Chat with Admin',
  logout: 'Log Out',
  allTeachers: 'All teachers',
  highLevel: 'High level',
  regular: 'Regular',
  teachers: 'Teachers',

  selected: 'selected',
  deselect: 'deselect',
  export: 'Export',
  delete: 'Delete',
  import: 'Import',
  filter: 'Filter',
  newTeacher: 'New Teacher',
  name: 'Name',
  activities: 'Activities',
  status: 'Status',
  created: 'Created',
  next: 'Next',
  previous: 'Previous',
  details: 'Details',
  resultsInfo: 'Showing <strong>{{from}}</strong> to <strong>{{to}}</strong> of <strong>{{total}}</strong> results',

  students: 'Students',
  newStudent: 'New Student',
  allStudents: 'All Students',
  mainParent: 'Main Parent',

  allActivities: 'All Activities',
  newActivity: 'New Activity',
  documents: 'Documents',
  allDocuments: 'All Documents',
  newDocument: 'New Document',

  email: 'Email',
  phone: 'Phone',
  category: 'Cetagory',
  regularSchool: 'Regular School',
  edit: 'Edit',
  noDetails: 'No Details',
  parent: 'Parent',
  loginForSchools: 'Log In for Schools',
  signupForSchools: 'SignUp for Schools',
  contact: 'Contact',
  privacyPolicy: 'Privacy Policy',

  catalan: 'Catalan',

  logIn: 'Log In',
  toYourAccount: 'to your account',
  password: 'Password',
  forgotPassword: 'Forgot password',
  register: 'Register',
  resetPassword: 'Reset Password',
  passwordDontMatch: 'The passwords do not match',
  repeatPassword: 'Repeat password',
  forYourAccount: 'for your account',
  backToLogin: 'Back to Log In page',
  checkYourEmail: 'Check your email.',

  allUsers: 'All Users',
  users: 'Users',
  newParent: 'New Parent',
  newFamily: 'New Family',
  parents: 'Parents',
  otherParents: 'Other Parents',
  siblings: 'Siblings',
  children: 'Children',
  date: 'Date',
  organizers: 'Organizers',
  teachingTeam: 'Teaching team',
  home: 'Home',

  isMainParent: 'Main',
  setMainparent: 'Set Main Parent',
  logWithGoogle: 'Log In with Google',

  add: 'Add',

  allParents: 'All Parents',
});

export default en;
