import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import eni18n from './lngs/en';
import esi18n from './lngs/es';
import cai18n from './lngs/ca';

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    detection: {
      lookupLocalStorage: 'safetykids-language',
    },
    resources: {
      en: {
        translation: eni18n(),
      },
      es: {
        translation: esi18n(),
      },
      ca: {
        translation: cai18n(),
      }
    },
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;