import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './header.scss';
import SearchForm from '../SearchForm/SearchForm';
import HeaderMenu from '../HeaderMenu/HeaderMenu';

import LogoImg from '../../images/logo-sk.svg';
import { Link } from 'react-router-dom';

function Header() {

  return (
    <div className='Header'>
      <Container>
        <Row className='Header-wrapper'>
          <Col md={2}>
            <Link to='/management/home'>
              <img src={LogoImg} alt="Safety Kids Manager" />
            </Link>              
          </Col>
          <Col md={5}>
            <SearchForm />
          </Col>
          <Col md={5}>
            <HeaderMenu />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
