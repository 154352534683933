import { initializeApp } from "firebase/app";
//import { createUser } from "src/api/user"
import {
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  signInWithPopup,
} from "firebase/auth";

import {
  getFirestore,
  query,
  getDocs,
  collection,
  addDoc,
  where,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBdZl_UBc0BxudaQ8E0Dy-_u5xXmKSzAIg",
  authDomain: "pc-api-7314606368094517235-925.firebaseapp.com",
  projectId: "pc-api-7314606368094517235-925",
  storageBucket: "pc-api-7314606368094517235-925.appspot.com",
  messagingSenderId: "95909212001",
  appId: "1:95909212001:web:c00eb71c687bb9ebc95f20",
  measurementId: "G-Q18Z44DYJY"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async (): Promise<any> => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    console.log(docs)
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }

    return user;
  } catch (err) {
    console.error(err);
    throw Error(err);
  }
};

export const logInWithEmailAndPassword = (email: string, password: string): Promise<any> => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const registerWithEmailAndPassword = async (name: string, email: string, password: string) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
    //const finalUser = await createUser(user, "teacher");
    return user;
  } catch (err) {
    console.error(err);

    return false;
  }
};

export const sendPasswordReset = (email: string): Promise<any> => {
  return sendPasswordResetEmail(auth, email);
};

export const logout = (): Promise<any> => {
  return signOut(auth);
};

const firebase = {
  auth,
  db,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};

export default firebase;
