import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

import FormControl from 'src/components/FormControl/FormControl';
import HomeFooter from 'src/components/HomeFooter/HomeFooter';
import CenteredLayout from 'src/components/Layout/Centered';
import Stripe from 'src/components/Layout/Stripe';

import { registerWithEmailAndPassword } from 'src/api/firebase';

import SpinnerImg from '../../images/spinner-white.svg';

interface registerInterface {
  email: string,
  password: string,
  repeatPassword: string,
};

function Register() {
  const { register, handleSubmit, watch, formState: { errors} } = useForm({});
  const [loading, setLoading] = React.useState(false);
  const password = watch('password');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onRegister = (data: registerInterface) => {
    setLoading(true);
    registerWithEmailAndPassword(data.email, data.email, data.password)
      .then(() => {
        navigate('/login');
      })
      .catch((e) => {
        setLoading(false);
        alert('Error');
        console.log(e);
      });
  };

  return (
    <Stripe withLines>
      <Container>
        <CenteredLayout withFooter>
          <h3 className='mb-5'>Safety Kids Manager</h3>
          <section className="CenteredForm">
            <form onSubmit={handleSubmit(onRegister)}>
              <h3 className='mb-4'>
                <span>{t('register')}</span>
                <br />
                {t('forYourAccount')}
              </h3>
              <div className="inputGroup">
                <FormControl
                  className="email"
                  placeholder={t('email')}
                  register={register}
                  type={'email'}
                  name={'email'}
                  errors={errors}
                  watch={watch}
                  disabled={loading}
                  validation={{ 
                    required: {
                      value: true,
                      message: t('isRequired'),
                    },
                    minLength: {
                      value: 8,
                      message: 'Min 8 symbols',
                    },
                    maxLength: {
                      value: 32,
                      message: 'Max 32 symbols',
                    },
                    pattern: {
                      value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                      message: 'Is not a email',
                    }
                  }}
                />

                <FormControl
                  className="password"
                  placeholder={t('password')}
                  register={register}
                  type={'password'}
                  name={'password'}
                  errors={errors}
                  disabled={loading}
                  watch={watch}
                  validation={{
                    required: {
                      value: true,
                      message: t('isRequired'),
                    },
                    minLength: {
                      value: 8,
                      message: 'Min 8 symbols',
                    },
                    maxLength: {
                      value: 32,
                      message: 'Max 32 symbols',
                    },
                  }}
                />

                <FormControl
                  className="password"
                  placeholder={t('repeatPassword')}
                  register={register}
                  type={'password'}
                  name={'repeatOassword'}
                  watch={watch}
                  errors={errors}
                  disabled={loading}
                  validation={{
                    required: {
                      value: true,
                      message: t('isRequired'),
                    },
                    minLength: {
                      value: 8,
                      message: 'Min 8 symbols',
                    },
                    maxLength: {
                      value: 32,
                      message: 'Max 32 symbols',
                    },
                    validate: (value: string) => {
                      return value === password || t('passwordDontMatch');
                    } 
                  }}
                />
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <Image className='spin' src={SpinnerImg} />
                  ) : t('register')}
                </button> 
              </div>
              <div className='mt-3'>
                <Link to="/login">{t('logIn')}</Link>
              </div>
            </form>
          </section>
        </CenteredLayout>
        <HomeFooter />
      </Container>
    </Stripe>
  );
}

export default Register;
