import * as React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Container from 'react-bootstrap/Container';
import { useTranslation } from "react-i18next";

import Image from 'react-bootstrap/Image';

import FormControl from 'src/components/FormControl/FormControl';
import HomeFooter from 'src/components/HomeFooter/HomeFooter';
import CenteredLayout from 'src/components/Layout/Centered';
import Stripe from 'src/components/Layout/Stripe';

import { sendPasswordReset } from "../../api/firebase";

import SpinnerImg from '../../images/spinner-white.svg';

interface resetInterface {
  email: string,
};

function ResetPassword() {
  const { register, handleSubmit, watch } = useForm();
  const [loading, setLoading] = React.useState(false);
  const [isReset, setReset] = React.useState(false);
  const { t } = useTranslation();

  const onReset = (data: resetInterface) => {
    setLoading(true);
    sendPasswordReset(data.email)
      .then(() => {
        setReset(true);
      })
      .catch(() => {
        setLoading(false);
        alert('Something went wrong');
      });
  };

  return (
    <Stripe withLines>
      <Container>
        <CenteredLayout withFooter>
          <h3 className='mb-5'>Safety Kids Manager</h3>
          <section className="CenteredForm">
            <form onSubmit={handleSubmit(onReset)}>
              <h3 className='mb-4'>
                <span>{t('resetPassword')}</span>
                <br />
                {t('toYourAccount')}
              </h3>
              {isReset ? (
                <p>
                  {t('checkYourEmail')}
                  {' '}
                  <Link to="/login">{t('backToLogin')}</Link>
                </p>
              ) : (
                <>
                  <div className="inputGroup">
                    <FormControl
                      className="email"
                      placeholder={t('email')}
                      register={register}
                      type={'email'}
                      name={'email'}
                      watch={watch}
                      validation={{ 
                        required: {
                          value: true,
                          message: t('isRequired'),
                        },
                        minLength: {
                          value: 8,
                          message: 'Min 8 symbols',
                        },
                        maxLength: {
                          value: 32,
                          message: 'Max 32 symbols',
                        },
                        pattern: {
                          value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                          message: 'Is not a email',
                        }
                      }}
                    />
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <Image className='spin' src={SpinnerImg} />
                      ) : t('resetPassword')}
                    </button>
                  </div>
                  <div className='mt-3'>
                    <Link to="/login">{t('logIn')}</Link>
                    {' | '}
                    <Link to="/register">{t('register')}</Link>
                  </div>
                </>
              )}
            </form>
          </section>
        </CenteredLayout>
        <HomeFooter />
      </Container>
    </Stripe>
  );
}

export default ResetPassword;
