import * as React from 'react';
import Container from 'react-bootstrap/Container';

import './footer.scss';

function Footer() {

  return (
    <div className='Footer'>
      <Container>
        &copy; {new Date().getFullYear()}, Safety Kids Manager
      </Container>
    </div>
  );
}

export default Footer;
