import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Container from 'react-bootstrap/Container';
import { useTranslation } from "react-i18next";
import { useAuthState } from "react-firebase-hooks/auth";

import Image from 'react-bootstrap/Image';

import FormControl from 'src/components/FormControl/FormControl';
import HomeFooter from 'src/components/HomeFooter/HomeFooter';
import CenteredLayout from 'src/components/Layout/Centered';
import Stripe from 'src/components/Layout/Stripe';

import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../../api/firebase";

import SpinnerImg from '../../images/spinner-white.svg';

import './login.scss';
import { useMutation, useQueryClient } from 'react-query';

interface loginInterface {
  email: string,
  password: string,
};

function Login() {

  const { register, handleSubmit, watch } = useForm();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [user, isLoading, error] = useAuthState(auth);

  const loginQuery = useMutation('fireBaseUser', ({ email, password }: any) => logInWithEmailAndPassword(email, password), {

    onSuccess: (data) => {
      queryClient.setQueryData('fireBaseUser', data);
      console.log(data);
    },

    onError: (error) => {
      console.log(error);
      setLoading(false);
      alert('Something went wrong');
    },
    
  });

  React.useEffect(() => {
    if (isLoading || error) {
      return;
    }
    if (user) navigate('/management/home');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading, error]);

  const googleQuery = useMutation('fireBaseUser', () => signInWithGoogle(), {
    onSuccess: (data) => {
      queryClient.setQueryData('fireBaseUser', data);
    },
    onError: (error) => {
      console.log(error);
      setLoading(false);
      alert('Something went wrong');
    },
  });

  const onLogin = (data: loginInterface) => {
    setLoading(true);
    loginQuery.mutate(data);
  };

  return (
    <Stripe withLines>
      <Container>
        <CenteredLayout withFooter>
          <h3 className='mb-5'>Safety Kids Manager</h3>
          <section className="CenteredForm">
            <form onSubmit={handleSubmit(onLogin)}>
              <h3 className='mb-4'>
                <span>{t('logIn')}</span>
                <br />
                {t('toYourAccount')}
              </h3>
              <div className="inputGroup">
                <FormControl
                  className="email"
                  placeholder={t('email')}
                  register={register}
                  type={'email'}
                  name={'email'}
                  watch={watch}
                  validation={{ 
                    required: {
                      value: true,
                      message: t('isRequired'),
                    },
                    minLength: {
                      value: 8,
                      message: 'Min 8 symbols',
                    },
                    maxLength: {
                      value: 32,
                      message: 'Max 32 symbols',
                    },
                    pattern: {
                      value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                      message: 'Is not a email',
                    }
                  }}
                />

                <FormControl
                  className="password"
                  placeholder={t('password')}
                  register={register}
                  type={'password'}
                  name={'password'}
                  watch={watch}
                  validation={{
                    required: {
                      value: true,
                      message: t('isRequired'),
                    },
                    minLength: {
                      value: 8,
                      message: 'Min 8 symbols',
                    },
                    maxLength: {
                      value: 32,
                      message: 'Max 32 symbols',
                    },
                  }}
                />
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loading}
                >
                  {(loading) ? (
                    <Image className='spin' src={SpinnerImg} />
                  ) : t('logIn')}
                </button>
              </div>
            </form>

            <div className='mt-3'>
              <button
                className="login__btn login__google"
                onClick={() => googleQuery.mutate()}
                disabled={loading}
              >
                {(loading) ? (
                  <Image className='spin' src={SpinnerImg} />
                ) : t('logWithGoogle')}
              </button>
            </div>

            <div className='mt-3'>
              <Link to="/reset-password">{t('forgotPassword')}</Link>
              {' | '}
            </div>
          </section>
        </CenteredLayout>
        <HomeFooter />
      </Container>
    </Stripe>
  );
}

export default Login;
