import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';
import { PersonInterface, QueryInterface, UserType } from 'src/config/interfaces';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { addStudentToParent, getPerson, getPersonList } from 'src/api/person';
import { getFamiliesById } from 'src/api/family';

import Layout from 'src/components/Layout/Layout';
import Badge from 'src/components/Badge/Badge';
import UserInfo from 'src/components/UserInfo/UserInfo';
import SubLinks from 'src/components/SubLinks/SubLinks';

import ProfileData from 'src/components/ProfileData/ProfileData';
import useDisclosure from 'src/utils/useDisclosure';
import UserForm from 'src/components/UserForm/UserForm';
import SelectProfile from 'src/components/SelectProfile/SelectProfile';
import LoadingLayout from 'src/components/Layout/Loading';

function Parent() {
  const params = useParams();
  const { t } = useTranslation();
  const { data, isLoading }: QueryInterface = useQuery(['parent', params.parentId], () => getPerson(params.parentId || '', 'parent'));
  const familyIds = data?.person.familyIds || [];
  const { data: familyData } = useQuery(['family', familyIds.length && familyIds[0]], () => getFamiliesById(familyIds[0] || ''), {
    enabled: !!familyIds && familyIds.length > 0
  });
  const queryClient = useQueryClient();
  const selectStudentModal = useDisclosure();
  const newStudentModal = useDisclosure();
  const { data: studentsData } = useQuery('studentIds', () => getPersonList('student'));
  const studentIds = studentsData ? studentsData.values : [];
  const doUpdateFamily = useMutation(
    ({ parentId, studentId }: any) => addStudentToParent(parentId, studentId),
    {
    onSuccess: () => {
      queryClient.invalidateQueries(['family', data?.person.familyId]);
    }
  });

  const parentSubLinks = [
    {
      title: 'Overview',
      id: 'overview',
      url: `/management/parents/parent/${params.parentId}`,
    },
    {
      title: 'Schedule',
      id: 'schedule',
      url: `/management/parents/parent/${params.parentId}/schedule`,
    },
    {
      title: 'Logs',
      id: 'logs',
      url: `/management/parents/parent/${params.parentId}/logs`,
    },
  ]

  const addToFamily = (type: UserType, userData: PersonInterface | undefined) => async (data: any, studentId: string) => {
    doUpdateFamily.mutate(
      { parentId: params.parentId, studentId }
    )
  };

  return (
    <Layout>
      {newStudentModal.isOpen && (
        <UserForm
          onHide={newStudentModal.onClose}
          type='student'
          title="Add new sibling"
          callBack={addToFamily('student', data?.person)}
          familyId={familyData.value.id}
        />
      )}
      {selectStudentModal.isOpen && (
        <SelectProfile
          onHide={selectStudentModal.onClose}
          type='student'
          title="Select student"
          ids={studentIds}
          callBack={(profileId: string) => addToFamily('student', data?.person)({}, profileId)}
        />
      )}
      <Container>
        <LoadingLayout isLoading={isLoading}>
          <Row>
            <Col xl={3}>
              <UserInfo userData={data} />
            </Col>
            <Col xl={9}>
              <SubLinks sublinks={parentSubLinks} />

              <div className='mt-5'>
                <div className='Teacher-detailsblock'>
                  <Row>
                    <Col className='Teacher-detailsblock-title'>
                      {t('otherParents')}
                    </Col>
                  </Row>
                </div>
                <Table borderless className='Teachers-table Teachers-table-nofirstpadding'>
                  <thead>
                    <tr>
                      <th style={{ width: '70%' }}>Name</th>
                      <th />
                      <th>Status</th>
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    {(familyData?.value.parentIds || [])
                    .filter((id: string) => id !== params.parentId)
                    .map((id: string) => (
                      <tr>
                        <td>
                          <ProfileData
                            type="parent"
                            id={id}
                            variant="short-profile"
                          />
                        </td>
                        <td>
                          <Link to={`/management/parents/parent/${id}`}>Details</Link>
                        </td>
                        <td>
                          <Badge type='success'>
                            Active
                          </Badge>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className='mt-5'>
                <div className='Teacher-detailsblock'>
                  <Row>
                    <Col className='Teacher-detailsblock-title'>
                      {t('children')}
                    </Col>
                    <Col className='text-end'>
                      <Button className='td-none' variant="link" onClick={() => selectStudentModal.onOpen()}>Add</Button>
                      <Button className='td-none' variant="link" onClick={() => newStudentModal.onOpen()}>Create</Button>
                    </Col>
                  </Row>
                </div>
                <Table borderless className='Teachers-table Teachers-table-nofirstpadding'>
                  <thead>
                    <tr>
                      <th style={{ width: '70%' }}>Name</th>
                      <th />
                      <th>Status</th>
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    {(familyData?.value.childIds || [])
                    .map((id: string) => (
                      <tr>
                        <td>
                          <ProfileData
                            type="student"
                            id={id}
                            variant="short-profile"
                          />
                        </td>
                        <td>
                          <Link to={`/management/students/student/${id}`}>Details</Link>
                        </td>
                        <td>
                          <Badge type='success'>
                            Active
                          </Badge>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

            </Col>
          </Row>
        </LoadingLayout>
      </Container>
    </Layout>
  );
}

export default Parent;
