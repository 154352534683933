import * as React from 'react';
import axios from 'axios';

import { API_URL } from 'src/config/api'
//import { useFilePicker } from 'use-file-picker';
import { token } from "src/api/user";
import Layout from 'src/components/Layout/Layout';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import uploadButton from '../../images/uploadButton.svg';

//import { useTranslation} from "react-i18next";

import './management.scss';

function Management() {
  //const { t } = useTranslation();
  console.log(token)
  const [selectedFile, setSelectedFile] = React.useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault()
    if(selectedFile){
      alert("El archivo se esta subiendo, esto puede tardar unos segundos, espere al siguiente mensaje")
      const formData = new FormData();
      formData.append("selectedFile", selectedFile!);
      try {
        const response = await axios({
          method: "post",
          url: `${API_URL}/v1/object/massive_upload`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` },
        });
        alert("Archivo subido correctamente")
        console.log(response)
      } catch(error) {
        alert("Error al subir el archivo")
        console.log(error)
      }
    }else{
      alert("Porfavor seleccione un archivo")
    }
  }
  const fileUpload = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  
  const handleClick = event => {
    fileUpload.current.click();
  };
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files![0])
  }

  return (
      <Layout>
        <Container>
          <h3 className='bold'>Today</h3>
          <Row>
            <Col xl={10}>
            <form id="fileForm" onSubmit={handleSubmit}>
            <img src={uploadButton} onClick={handleClick} alt='shirt' width={70} style={{marginRight:40}}/>
              <input type="file" ref={fileUpload} onChange={handleFileSelect} style={{display:'none'}}/>
              <button type="submit" className='HomeHeader-signup-btn'>Subir archivo </button>
            </form>
            </Col>
          </Row>
        </Container>
      </Layout>
  );
}

export default Management;
